import React, { useEffect, useState } from 'react'
import { Link, Grid, GridItem, Image, Text, Box } from "@chakra-ui/react";
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import Frame from "react-frame-component";
import { scroller } from "react-scroll";
import { contentful } from "../Config/contentful";
import { formatCurrentNewsLetter, formatOctagons, formatOldNewsLetters, formatRichTextStyle } from "../utils/formatElements";
import Header from '../Components/Header/index';
import Footer from '../Components/Footer/index';
import Hexagone from '../Components/Common/Hexagone';

const NewsLetters = () => {
  const [newsLettersData, setNewsLetterData] = useState(null);
  const [hexagonData, setHexagonData] = useState(null);
  const [oldNewsLettersData, setOldNewsLettersData] = useState(null);

  const getContentfulData = async () => {
    const response = await contentful.getEntries("");
    if(!response.items) return;

    const octagonCollection = [];
    const oldNewsLetterCollection = [];

    await response.items.forEach((item) => {
      const {sys: {contentType: {sys: {id}}}} = item;

      // Get Collection of elements
      switch (id.toLowerCase()) {
        case "octagondetail":
          octagonCollection.push(item);
          break;
        case "newsletterpage":
          setNewsLetterData(formatCurrentNewsLetter(item));          
          break;
        case "oldnewsletter":
          oldNewsLetterCollection.push(item);
          break;
        default:
          break;
      }
    });
    setOldNewsLettersData(formatOldNewsLetters(oldNewsLetterCollection));
    setHexagonData(formatOctagons(octagonCollection));
  }

  useEffect( () => {
    getContentfulData();
    scroller.scrollTo('content', { smooth: true });
  }, []);

  return (
    <>
      <Header />
      <Box
        id="content"
        pt={'200px'}
        className='container'
      >
        <Grid        
          fontFamily={"Roboto"}
          fontSize={"20px"}
          columnGap={'20px'}
          rowGap={'20px'}
          gridTemplateColumns="repeat(2, 1fr)"
          padding='50px 0px 100px 0px'
          className='newsletter-first-grid'
        >
          <GridItem>
            <Text
              marginTop={"30px"}
              fontSize={{ base: "15px", md: "20px", lg: "30px" }}
              lineHeight={{ base: "18px", md: "23px", lg: "33px" }}
              color={"black"}
              fontWeight={"medium"}
              fontFamily={'"Roboto"'}
              mb={'25px'}
              maxW={'1130px'}
              px={'80px'}
              dangerouslySetInnerHTML={{ __html:formatRichTextStyle(documentToHtmlString(newsLettersData?.desc)) }}
            ></Text>
            <Text
              marginTop={"30px"}
              fontSize={{ base: "15px", md: "20px", lg: "30px" }}
              lineHeight={{ base: "18px", md: "23px", lg: "33px" }}
              color={"black"}
              fontWeight={"medium"}
              fontFamily={'"Roboto"'}
              mb={'25px'}
              maxW={'1130px'}
              px={'80px'}
              dangerouslySetInnerHTML={{ __html: formatRichTextStyle(documentToHtmlString(newsLettersData?.descArchive)) }}
            ></Text>
          </GridItem>
          <GridItem>
            <Link isExternal href={newsLettersData?.link}>
              <Image maxW={'450px'} src={newsLettersData?.img} />
            </Link>
          </GridItem>
        </Grid>
        <Grid 
          fontFamily={"Roboto"}
          fontSize={"20px"}
          columnGap={'30px'}
          rowGap={'30px'}
          gridTemplateColumns={['repeat(4, 1fr)', ]}
          padding={'20px'}
          className='newsletter-sec-grid'
        >
          {
            oldNewsLettersData?.sort((a,b) => a.order - b.order).map((item, index) => <>
              <GridItem key={'old-newsletter' + index}>
                <Link isExternal href={item?.link}>
                  <Box>
                    <Image src={item?.img} />
                    <Text 
                      lineHeight='50px'
                      fontSize='22px'
                      textDecor='underline'
                      color='#53a3ca'
                    >{item?.title}</Text>
                  </Box>
                </Link>
              </GridItem>
            </>)
          }
        </Grid>
        <Frame
          id="donate-form"
          initialContent={null}
          src={'./bboxform/subscribe.html'}        
          className='subscribe-form'
        ></Frame>
      </Box>
      <Hexagone elements={hexagonData} pb={"200px"} dropShadow={'drop-shadow(rgba(0, 0, 0, 0.9) 0px 0px 15px)'} />
      <Footer />
    </>
  )
}

export default NewsLetters