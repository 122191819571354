import AWS from 'aws-sdk';

const S3_config = {
  credentials: {
    accessKeyId: process.env.REACT_APP_AUDIO_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AUDIO_AWS_SECRET_ACCESS_KEY,
  },
  region: process.env.REACT_APP_AUDIO_AWS_REGION,
  accountName: process.env.REACT_APP_AUDIO_AWS_ACCOUNT_NAME,
  Bucket: process.env.REACT_APP_AUDIO_AWS_BUCKET,
  CloudFrontDomainName : process.env.REACT_APP_AUDIO_AWS_CLOUD_FRONT_DOMAIN,
  expiresIn: process.env.REACT_APP_AUDIO_AWS_S3_EXPIRE_TIME,
}

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AUDIO_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AUDIO_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AUDIO_AWS_REGION
});
const S3 = new AWS.S3();
export {S3, S3_config}
