import React, { Suspense,lazy } from "react";
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import AboutUs from "../Pages/AboutUs";
import Home from "../Pages/Home";
import PrivacyPolicy from "../Pages/PrivacyPolicy";
import Careers from "../Pages/Careers";
import NewsLetters from "../Pages/NewsLetters";
import KnottedStrings from "../Pages/KnottedStrings";
import Testimonies from '../Pages/Testimonies';
import TestingAudio from "../Pages/TestingAudio";

const R = () => {
  const HomeScreen = lazy(() => import("../Pages/Home"));

  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" caseSensitive={false} element={<Home />} />
          <Route path="/home" caseSensitive={false} element={<HomeScreen />} />
          <Route path="/about-us/:id" caseSensitive={false} element={<AboutUs />} />
          <Route path="contact" caseSensitive={false} element={<Home />} />
          <Route path="/privacy-policy" caseSensitive={false} element={<PrivacyPolicy />} />
          <Route path="/career" caseSensitive={false} element={<Careers />} />
          <Route path="/faq" caseSensitive={false} element={<PrivacyPolicy isFaq />} />
          <Route path="/testimonies" caseSensitive={false} element={<Testimonies />} />
          <Route path="/testimonies/:id" caseSensitive={false} element={<Testimonies />} />

          <Route path="/changing-lives" element={<Navigate to="/testimonies" />} />
          <Route path="/donate" element={<Navigate to="/#donate" />} />
          <Route path="/planned-giving" element={<Navigate to="/#donate" />} />
          <Route path="/ways-to-help" element={<Navigate to="/#donate" />} />
          <Route path="/ways-to-help/planned-giving" element={<Navigate to="/planned-giving" />} />
          <Route path="/store" element={<Navigate to="/#store" />} />
          <Route path="/newsletter" element={<Navigate to="/#newsletter" />} />
          <Route path="/videos" element={<Navigate to="/#videos" />} />
          <Route path="/newsletters" element={<NewsLetters />} />
          <Route path="/knotted-strings" element={<KnottedStrings />} />
          <Route path="/testing-audio" element={<TestingAudio url="https://www.audio-lingua.eu/IMG/mp3/adoutre-vorurteile-typisch-franzosen.mp3" />} />
        </Routes>
      </Suspense>
    </Router>
  );
};
export default R;
