const formatPath = (location) => {
  const pathEls = location.pathname.split('-').join('').substring(1).toLocaleLowerCase().split('/');
  const pathName = pathEls[0].charAt(0).toUpperCase() + pathEls[0].slice(1);
  return {
    path: pathEls[0],
    param: pathEls[1],
    pathName
  };
};

const formatStringForCapital = (string) => {
  return '/' + string.split(" ").join('-').toLocaleLowerCase();
}

export { formatPath, formatStringForCapital };