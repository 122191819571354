import React, { useState, useEffect, createRef } from "react";
import { Center, Text } from '@chakra-ui/react';
import Frame from "react-frame-component";
import { scroller } from "react-scroll";
import "./DonationForm.css";

const BboxForm = ({
  formId,
  data
}) => {
  const [height, setHeight] = useState(1000);
  const iframeRef = createRef();

  const handleResize = (iframe) => {
    const h = iframe.current?.contentDocument?.body?.childNodes[0]?.scrollHeight ?? 0;
    if (h !== 0 && h > 100) {
      setHeight(h +90);
    }
  };

  const getUrl = () => {
    switch (formId) {
      case 0:
        return './bboxform/donate.html';
      case 2:
        return './bboxform/subscribe.html';
      default:
        break;
    }
  }
  
  const getClassName = () => {
    switch (formId) {
      case 0:
        return 'donate-form';
      case 2:
        return 'subscribe-form';
      default:
        break;
    }
  }

  const getStyles = () => {
    return {
      height: formId === 0 ? height : '0px',
      paddingTop: formId === 0 ? '60px' : '0px', 
    }
  }
  
  useEffect(() => {
    if (formId === 0) {
      const interval = setInterval(() => {
        handleResize(iframeRef);
      }, 100);
      return () => {
        clearInterval(interval);
      }
    }
  }, [])

  
  return (
    <Center flexDir={'column'}>
      <Text 
        padding={'40px 20px 0px 20px'}
        fontSize={{
          base: "18px",
          md: "18px",
          lg: "20px",
          xl: "25px",
          "2xl": "30px",
        }}
        lineHeight={'1.1'}
        maxW={{
          md: "90%",
          lg: "90%",
          xl: "90%",
          "2xl": "1190px",
        }} 
        dangerouslySetInnerHTML={{ __html: data?.text }}
      ></Text>
      <Frame
        id="donate-form"
        initialContent={null}
        src={getUrl()}        
        ref={iframeRef}        
        className={getClassName()}
        style={{...getStyles()}}
      >
      </Frame>
    </Center>
  )
}

export default BboxForm