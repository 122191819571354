import { createSlice } from "@reduxjs/toolkit";
import { 
  getProducts, 
  getCheckout, 
  createCheckout, 
  addLineItems, 
  updateLineItems,
  removeLineItems,
  toggleCart
} from './actions';

const PREFIX = 'shopify';
const initialState = {
  checkout: null,
  products: null,
  openCart: false,
};

export const shopifySlice = createSlice({
  name: PREFIX,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getProducts.fulfilled.type,
        (state, action) => {
          state.products = action.payload;
        }
      )
      .addCase(
        getCheckout.fulfilled.type,
        (state, action) => {
          state.checkout = action.payload;
        }
      )
      .addCase(
        createCheckout.fulfilled.type,
        (state, action) => {
          state.checkout = action.payload;
          localStorage.setItem('checkout_id', action.payload.id);
        }
      )
      .addCase(
        addLineItems.fulfilled.type,
        (state, action) => {
          state.checkout = action.payload;
        }
      )
      .addCase(
        updateLineItems.fulfilled.type,
        (state, action) => {
          state.checkout = action.payload;
        }
      )
      .addCase(
        removeLineItems.fulfilled.type,
        (state, action) => {
          state.checkout = action.payload;
        }
      )
      .addCase(
        toggleCart,
        (state, action) => {
          state.openCart = !state.openCart;
        }
      )
  }
});

export { getProducts, getCheckout, createCheckout, addLineItems, updateLineItems, removeLineItems, toggleCart };

export default shopifySlice.reducer;