import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  FormControl,
  FormErrorMessage,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
} from "@chakra-ui/react";
import ThankYou from "./ThankYou";

const ContactUs = ({ isOpen, onClose }) => {
  const [success, setSuccess] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    fetch(process.env.REACT_APP_USEBASIN_API, {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: formData,
    })
      .then((res) => {
        onClose();
        setTimeout(() => {
          setSuccess(res.status === 200);
        }, 300);
      })
      .catch((err) => {
        onClose();
        console.error(err);
      });
  };

  useEffect(() => {
    reset();
  }, [isOpen]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        blockScrollOnMount={false}
      >
        <ModalOverlay />
        <form
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <ModalContent fontFamily={"Roboto"}>
            <ModalHeader>Contact Us</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl isInvalid={!!errors.name}>
                <Input
                  name="name"
                  placeholder="Full Name"
                  borderRadius={0}
                  {...register("name", { required: "Name is required." })}
                />
                <FormErrorMessage>
                  {errors.name ? errors.name.message : ""}
                </FormErrorMessage>
              </FormControl>
              <FormControl mt={4} isInvalid={!!errors.email}>
                <Input
                  type={"email"}
                  name="email"
                  placeholder="Email Address"
                  borderRadius={0}
                  {...register("email", {
                    required: "Email is required.",
                    pattern: {
                      value: /^\S+@\S+$/i,
                      message: "Email type is incorrect.",
                    },
                  })}
                />
                <FormErrorMessage>
                  {errors.email ? errors.email.message : ""}
                </FormErrorMessage>
              </FormControl>
              <FormControl mt={4} isInvalid={!!errors.phone}>
                <Input
                  name="phone"
                  placeholder="Phone Number"
                  borderRadius={0}
                  {...register("phone", {
                    required: "Phone number is required.",
                  })}
                />
                <FormErrorMessage>
                  {errors.phone ? errors.phone.message : ""}
                </FormErrorMessage>
              </FormControl>
              <FormControl mt={4} isInvalid={!!errors.message}>
                <Textarea
                  name="message"
                  placeholder="Message"
                  borderRadius={0}
                  {...register("message", {
                    required: "Message is required.",
                  })}
                />
                <FormErrorMessage>
                  {errors.message ? errors.message.message : ""}
                </FormErrorMessage>
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" mr={3} type={"submit"}>
                Submit
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
      <ThankYou isOpen={success} onClose={() => setSuccess(false)} />
    </>
  );
};

export default ContactUs;
