import "../App.css";
import React, { useEffect, useState } from "react";
import Header from "../Components/Header/index";
import Banner from "../Components/Banner";
import Invitation from "../Components/Invitation/invitation";
import ListenBible from "../Components/Bible/Listen";
import TalkingBible from "../Components/Bible/TalkingBible";
import Testimonials from "../Components/Bible/Testimonials";
import Information from "../Components/Information";
import Africa from "../assests/Africa.png";
import india from "../assests/india.png";
import About from "../Components/About/About";
import div2 from "../assests/bg1.jpg";
import div3 from "../assests/indabg2.png";
import pay from "../assests/pink-blue.png";
import pay2 from "../assests/brown-blue.png";
import Footer from "../Components/Footer";
import VideoInformation from "../Components/VideoInformation";
import Hexagone from "../Components/Common/Hexagone";
import indiabg from "../assests/indiabg@.jpg";
import africaBg from "../assests/if.png";
import { contentful } from "../Config/contentful";
import { formatBanners, formatSlider, formatTestimonials, formatOctagons, formatAboutUs, formatTalkingBible, formatWatchVideo, formatDonationForm, formatListenBibleText } from "../utils/formatElements";

import { useMediaQuery } from "@chakra-ui/react";

function Home() {
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const [sliderData, setSliderData] = useState(null);
  const [testimonialData, setTestimonialData] = useState(null);
  const [hexagonData, setHexagonData] = useState(null);
  const [aboutUsData, setAboutUsData] = useState(null);
  const [watchVideoData, setWatchVideoData] = useState(null);
  const [talkingBibleData, setTalkingBibleData] = useState(null);
  const [donationFormData, setDonationFormData] = useState(null);
  const [listenBibleTextData, setListenBibleTextData] = useState(null);
  const [bannerData, setBannerData] = useState(null);

  const getContentfulData = async () => {
    const response = await contentful.getEntries("");
    if(!response.items) return;

    const testimonialCollection = [];
    const octagonCollection = [];
    const aboutUsCollection = [];
    const videoCollection = [];
    const bannerCollection = [];
    await response.items.forEach((item) => {
      const {sys: {contentType: {sys: {id}}}} = item;

      // Get Collection of elements
      switch (id) {
        case "homeSlider":
          setSliderData(formatSlider(item));
          break;
        case 'testimonialsDetail':
          testimonialCollection.push(item);
          break;
        case 'bannerImage':
          bannerCollection.push(item);
          break;
        case 'octagonDetail':
          octagonCollection.push(item);
          break;
        case 'whatWeDo':
          aboutUsCollection.push(item);
          break;
        case 'whyTalkingBible':
          setTalkingBibleData(formatTalkingBible(item));
          break;
        case 'watchGlobalFrontierMissionsVideo':
          setWatchVideoData(formatWatchVideo(item));
          break;
        case 'carouselVideoForWatchGlobalFrontierMissions':
          const { fields: { video }} = item;
          videoCollection.push(video);
          break;
        case 'donationForm':
          setDonationFormData(formatDonationForm(item));
          break;
        case 'listenBibleText':
          setListenBibleTextData(formatListenBibleText(item));
          break;
        default:
          break;
      }
    });
    setWatchVideoData(prev => ({...prev, videos: videoCollection}))
    setTestimonialData(formatTestimonials(testimonialCollection).slice(-12)?.reverse());
    setHexagonData(formatOctagons(octagonCollection));
    setAboutUsData(formatAboutUs(aboutUsCollection));
    setBannerData(formatBanners(bannerCollection));
  }

  useEffect( () => {
    getContentfulData();
  }, []);

  return (
    <>
      <Header/>
      <Banner img={sliderData?.img ?? ''} elements={sliderData?.elements ?? []} />
      <Hexagone elements={hexagonData} dropShadow={'drop-shadow(rgba(0, 0, 0, 0.9) 0px -9px 15px)'}/>
      {
        bannerData?.length > 0 && (
          <Invitation
            icon={bannerData[0].octagonImg}
            name={bannerData[0].title}
            link={bannerData[0].link}
            alt={bannerData[0].alt}
            image={bannerData[0].img}
          />
        )
      }
      <ListenBible elements={listenBibleTextData} />
      <TalkingBible elements={talkingBibleData}/>
      <Information donationFormData={donationFormData} />
      <Testimonials elements={testimonialData}/>
      {
        bannerData?.length > 1 && (
          <Invitation
            index={2}
            name={bannerData[1].title}
            icon={bannerData[1].octagonImg}
            link={bannerData[1].link}
            alt={bannerData[1].alt}
            image={bannerData[1].img}
          />
        )
      }
      <About elements={aboutUsData} />
      <VideoInformation elements={watchVideoData}/>
      <Footer />
    </>
  );
}

export default Home;
