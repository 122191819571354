import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { shopify } from "../../Config/shopify";
import { formatGraphModelToArry } from '../../utils/formatGraphModel';

const getProducts = createAsyncThunk('shopify/products', async() => {

});

const getCheckout = createAsyncThunk('shopify/checkout/get', async(checkoutId) => {
  let response = await shopify.checkout.fetch(checkoutId);
  if (!response) {
    response = await shopify.checkout.create();
  }
  return formatGraphModelToArry(response);
})

const createCheckout = createAsyncThunk('shopify/checkout/create', async() => {
  const response = await shopify.checkout.create();
  return formatGraphModelToArry(response);
});

const addLineItems = createAsyncThunk('shopify/addLineItems', async({checkoutId, lineItemsToadd}) => {
  const response = await shopify.checkout.addLineItems(checkoutId, lineItemsToadd);
  return formatGraphModelToArry(response);
});

const updateLineItems = createAsyncThunk('shopify/updateLineItems', async({checkoutId, lineItemsToUpdate}) => {
  const response = await shopify.checkout.updateLineItems(checkoutId, lineItemsToUpdate);
  return formatGraphModelToArry(response);
});

const removeLineItems = createAsyncThunk('shopify/removeLineItems', async({checkoutId, lineItemsToRemove}) => {
  const response = await shopify.checkout.removeLineItems(checkoutId, lineItemsToRemove);
  return formatGraphModelToArry(response);
});

const toggleCart = createAction('toggleCart');

export { getProducts, getCheckout, createCheckout, addLineItems, updateLineItems, removeLineItems, toggleCart };