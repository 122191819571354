import { Grid, GridItem, Image, Text } from "@chakra-ui/react";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { scroller } from 'react-scroll';
import { contentful } from "../../Config/contentful";
import Header from "../../Components/Header/index";
import Hexagone from "../../Components/Common/Hexagone";
import Footer from "../../Components/Footer/index";
import { formatAboutUs, formatOctagons } from "../../utils/formatElements";
import './style.scss';
import { formatStringForCapital } from "../../utils/formatPath";
import { useNavigate  } from 'react-router-dom';

const AboutUs = () => {
  const param = useParams();
  const navigate = useNavigate();
  const [hexagonData, setHexagonData] = useState(null);
  const [aboutUsData, setAboutUsData] = useState(null);

  const getContentfulData = async () => {
    const response = await contentful.getEntries("");
    if (!response.items) return;

    const octagonCollection = [];
    const aboutUsCollection = [];
    await response.items.forEach((item) => {
      const {
        sys: {
          contentType: {
            sys: { id },
          },
        },
      } = item;

      switch (id.toLowerCase()) {
        case "octagondetail":
          octagonCollection.push(item);
          break;
        case 'whatwedo':
          aboutUsCollection.push(item);
          break;
        default:
          break;
      }
    });
    setHexagonData(formatOctagons(octagonCollection));
    setAboutUsData(formatAboutUs(aboutUsCollection)?.find(s => formatStringForCapital(s.name) === '/' + param.id));
  };

  useEffect(() => {
    scroller.scrollTo('content');
    getContentfulData();
  }, []);

  return (
    <div>
      <Header />
      <Image 
        w={'100%'}
        h={'50vh'}
        maxH={'700px'}
        src={aboutUsData?.headerImage ?? ''}
        objectFit={'cover'}
        alt="Header"
      />
      <Grid
        id="content"
        fontFamily={"Roboto"}
        bgColor={"#F1DFEB"}
        fontSize={"20px"}
        columnGap={'20px'}
        rowGap={'20px'}
        className={'grid-first'}
      >
        <GridItem>
          <Text
            dangerouslySetInnerHTML={{
              __html: documentToHtmlString(aboutUsData?.title),
            }}
            fontSize={"30px"}
            fontWeight={"bold"}
          ></Text>
          <Text
            mt={'20px'}
            dangerouslySetInnerHTML={{
              __html: documentToHtmlString(aboutUsData?.line1Col1),
            }}
          ></Text>
        </GridItem>
        <GridItem>
          <Image
            src={aboutUsData?.line1Col2}
            width={"100%"}
            height={"100%"}
            objectFit={"cover"}
            alt="about us"
          />
        </GridItem>
        <GridItem>
          <Image
            src={aboutUsData?.line1Col3}
            width={"100%"}
            height={"100%"}
            objectFit={"cover"}
            alt="about us"
          />
        </GridItem>
        <GridItem>
          <Image
            src={aboutUsData?.line2Col1}
            width={"100%"}
            height={"100%"}
            objectFit={"cover"}
            alt="about us"
          />
        </GridItem>
        <GridItem>
          <Text
            dangerouslySetInnerHTML={{
              __html: documentToHtmlString(aboutUsData?.line2Col2),
            }}
          ></Text>
        </GridItem>
        <GridItem>
          <Text
            dangerouslySetInnerHTML={{
              __html: documentToHtmlString(aboutUsData?.line2Col3),
            }}
          ></Text>
        </GridItem>
      </Grid>
      <Grid
        fontSize={"20px"}
        fontFamily={"Roboto"}
        bgColor={"#F1DFEB"}
        columnGap={'20px'}
        rowGap={'20px'}
        className={'grid-sec'}
      >
        <GridItem>
          <Text
            dangerouslySetInnerHTML={{
              __html: documentToHtmlString(aboutUsData?.line3Col1),
            }}
          ></Text>
        </GridItem>
        <GridItem 
          className={'colspan-2'}
        >
          <Image
            src={aboutUsData?.line3Col2}
            width={"100%"}
            height={"100%"}
            objectFit={"cover"}
            alt="about us"
          />
        </GridItem>
      </Grid>
      <Grid
        fontSize={"20px"}
        fontFamily={"Roboto"}
        className={aboutUsData?.imageHowItAllStarted ? 'grid-third-how-it-works' : 'grid-third'}
      >
        <GridItem className="you-can-help" backgroundColor={"#E9D0A4"}>
          <Text
            dangerouslySetInnerHTML={{
              __html: documentToHtmlString(aboutUsData?.line4Col1),
            }}
          ></Text>
        </GridItem>
        {
          aboutUsData?.imageHowItAllStarted 
          ? <>
            <GridItem backgroundColor={"#A1C7C6"} className="col-sec" cursor='pointer' onClick={() => navigate('/knotted-strings')}>
              <Text
                fontSize={"20px"}
                fontWeight={"bold"}
              >{aboutUsData?.titleHowItAllStarted}</Text>
              <Text>{aboutUsData?.descHowItAllStarted}</Text>
            </GridItem>
            <GridItem 
              backgroundColor={"#A1C7C6"}
              className="col-third"
              cursor='pointer'
              onClick={() => navigate('/knotted-strings')}
            >
              <Image
                src={aboutUsData?.imageHowItAllStarted}
                width={"100%"}
                height={"100%"}
                objectFit={"cover"}
                alt="about us"
              />
            </GridItem>
          </> 
          : <>
            <GridItem
              backgroundColor={"#A1C7C6"}
              pb={"225px"}
              className={'colspan-2'}
            >
              <Text
                dangerouslySetInnerHTML={{
                  __html: documentToHtmlString(aboutUsData?.line4Col2),
                }}
              ></Text>
            </GridItem>
          </>
        }
      </Grid>
      <Hexagone elements={hexagonData} pb={"200px"} dropShadow={'drop-shadow(rgba(0, 0, 0, 0.9) 0px 0px 15px)'} />
      <Footer />
    </div>
  );
};

export default AboutUs;
