const formatSlider = (item) => {
  const data = {img: '', elements: []};
  Object.entries(item?.fields).forEach(([key, value]) => {
    const els = key.split("_");
    const index = els.length > 1 ? els[1] : '0';
    const val = data.elements.findIndex(s => s.id && s.id === index);
    if (key === 'sliderImage') {
      data.img = formatImageField(value);
    } else {
      if (val === -1) {
        data.elements.push({
          id: index,
          [els[0]]: els[0] === 'sliderImage' ? formatImageField(value) : value, 
        });
      } else  {
        data.elements[val][els[0]] = value;
      }
    }
  });
  return data;
};

const formatOctagons = (items) => {
  return items
    .map(({ fields: { amount, title, intro, image, order } }) => {
      return {
        order,
        amount,
        title,
        img: formatImageField(image),
        intro,
      };
    })
    .sort((a, b) => a.order - b.order);
};

const formatBanners = (items) => {
  return items
    .map(({fields: {title, order, alt, link, image, octagonImage}}) => {
      return {
        order, 
        title, 
        link,
        alt,
        img: formatImageField(image),
        octagonImg: formatImageField(octagonImage)
      }
    })
    .sort((a, b) => a.order - b.order);
}

const formatTestimonials = (items) => {
  const els = items
    .map(({ fields: { isFirstOfAll, content, intro, image, title, order, metaTitle, metaDescription, metaOgDescription, metaTwitterDescription } }) => {
      return {
        isFirstOfAll,
        order,
        title,
        content,
        intro,
        img: formatImageField(image),
        metaTitle,
        metaDescription,
        metaOgDescription,
        metaTwitterDescription
      };
    });

  const firstItem = els.find(s => s.isFirstOfAll);
  const others = els
    .filter(s => !s.isFirstOfAll)
    .sort((a, b) => a.order - b.order);
    
  if(firstItem) others.push(firstItem);
  return others;
};

const formatTestimonialHeader = (item) => {
  const {
    fields: { name, headerImage },
  } = item;
  return { name, headerImage: formatImageField(headerImage) };
};

const formatAboutUs = (items) => {
  return items
    .map(
      ({
        fields: {
          title,
          intro,
          headerImage,
          introImage,
          line1Col1,
          line1Col2,
          line1Col3,
          line2Col1,
          line2Col2,
          line2Col3,
          line3Col1,
          line3Col2,
          line4Col1,
          line4Col2,
          titleHowItAllStarted,
          descHowItAllStarted,
          imageHowItAllStarted,
          order,
          name
        },
        sys: {
          contentType: {
            sys: { id },
          },
        },
      }) => {
        return {
          id,
          order,
          title,
          intro,
          name,
          headerImage: formatImageField(headerImage),
          img: formatImageField(introImage),
          line1Col1,
          line1Col2: formatImageField(line1Col2),
          line1Col3: formatImageField(line1Col3),
          line2Col1: formatImageField(line2Col1),
          line2Col2,
          line2Col3,
          line3Col1,
          line3Col2: formatImageField(line3Col2),
          line4Col1,
          line4Col2,
          titleHowItAllStarted,
          descHowItAllStarted,
          imageHowItAllStarted: imageHowItAllStarted && formatImageField(imageHowItAllStarted),
        };
      },
    )
    .sort((a, b) => a.order - b.order);
};

const formatPrivacy = (items) => {
  return items
    .map(({ fields: { name, content, headerImage } }) => {
      return { name, content, headerImage: formatImageField(headerImage) };
    })
};

const formatCareers = (item) => {
  const {
    fields: { content, headerImage },
  } = item;
  return { content, headerImage: formatImageField(headerImage) };
};

const formatTalkingBible = (item) => {
  const {
    fields: { title, text, video }
  } = item;
  return {title, text, video};
}

const formatWatchVideo = (item) => {
  const {
    fields: { title, text, subTitle }
  } = item;
  return {
    title, text, subTitle
  };
}

const formatDonationForm = (item) => {
  const {
    fields: { text }
  } = item;
  const val = text.split('\n').join('<br>');
    
  return { text: val }
}

const formatOldNewsLetters = (items) => {
  return items.map(({
    fields: { title, image, link, order }
  }) => ({title, link, img: formatImageField(image), order}))
};

const formatCurrentNewsLetter = (item) => {
  const {
    fields: { actualNewsletter, actualNewsletterLink, actualNewsletterDesc, descArchive }
  } = item;
  return { 
    img: formatImageField(actualNewsletter),
    link: actualNewsletterLink,
    desc: actualNewsletterDesc,
    descArchive: descArchive
  };
}

const formatInternalDetail = (item) => {
  const {
    fields: { title, mainText, image1 }
  } = item;
  return {
    title, 
    image1: formatImageField(image1), 
    text: mainText
  };
}

const formatListenBibleText = (item) => {
  const {
    fields: { text }
  } = item;
  return {
    text
  }
}

const formatImageField = ({
  fields: {
    file: { url },
  },
}) => {
  return url;
};

const formatAudios = (items) => {
  return items.map((
    {fields: {
      title, 
      audio: { fields: { file: {url} }}
    }}
  ) => {
    return {
      title, url
    }
  })
}

const formatRichTextStyle = (val) => {
  return val
    .split("<b>")
    .join(
      `<br><br><b style="line-height: 50px; font-size: 25px; color: #53a3ca;">`,
    )
    .split("</b>")
    .join("</b><br>")
    .split("<p>")
    .join('<p style="font-size: 20px">')
    .split("<ul>")
    .join(
      `
      <ul style="margin-inline-start: 30px;
      list-style-type: disc;
      padding-inline-start: 40px;
      margin-block-start: 1em;
      margin-block-end: 1em;">`,
    )
    .split("<li>").join(`<li style="display: list-item;
      text-align: -webkit-match-parent;
      list-style: disc;
      box-sizing: inherit;">`)
    .split("<a").join(`<a style="text-decoration: underline;"`);
};

export {
  formatSlider,
  formatTestimonials,
  formatOctagons,
  formatAboutUs,
  formatPrivacy,
  formatCareers,
  formatTalkingBible,
  formatWatchVideo,
  formatDonationForm,
  formatCurrentNewsLetter,
  formatOldNewsLetters,
  formatInternalDetail,
  formatRichTextStyle,
  formatListenBibleText,
  formatImageField,
  formatAudios,
  formatBanners,
  formatTestimonialHeader
};
