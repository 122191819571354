import { TriangleUpIcon } from '@chakra-ui/icons'
import { Box, Text } from '@chakra-ui/react'
import React from 'react'

const CloseButton = ({
  iconColor, uptoId, onClick, 
}) => {
  return (
    <Box 
      textAlign={'center'} 
      cursor={'pointer'} 
      padding={'5px'} 
      borderWidth={'1px'} 
      borderStyle={'solid'} 
      borderRadius={'9px'} 
      borderColor={'transparent'}
      _active={{
        borderColor: iconColor
      }}
      onClick={() => onClick()}
    >
      <TriangleUpIcon color={iconColor} w={6} h={6}/>
      <Text
        fontFamily={"Roboto"} 
        fontSize={"17px"}
        fontWeight={'bold'}
      >
        Close
      </Text>
    </Box>
  )
}

export default CloseButton
