import React, { useState } from 'react'
import { Box } from '@chakra-ui/react'
import Carousel from 'react-multi-carousel'
import ReactPlayer from 'react-player'
import VideoCarouselItem from './VideoCarouselItem'
import './style.scss';

const VideoCarousel = ({videos}) => {
  const [selectedVideo, setSelectedVideo] = useState(null)
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 2999, min: 1299 },
      items:6,
    },
    tablet: {
      breakpoint: { max: 1298, min: 896 },
      items: 5,
    },
    mdTablet: {
      breakpoint: { max: 895, min: 465 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
    }
  };

  return (
    <>
      <ReactPlayer 
        url={'https:' + (selectedVideo ?? videos?.[0])} 
        className="selected-video-player"
        controls
        pip
      />
      <Box className="video-carousel">
        <Carousel responsive={responsive}>
          {
            videos ? videos.map((item, index) => (
              <VideoCarouselItem
                key={'mission-video' + index}
                onClick={() => setSelectedVideo(item)}
                videoSrc={item}
              />
            )) : []
          }
        </Carousel>
      </Box>
    </>
  )
}

export default VideoCarousel