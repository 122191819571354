import { configureStore } from '@reduxjs/toolkit';
import shopifyReducer from './shopify';
import staticReducer from './static';

const store = configureStore({
  reducer: {
    shopify: shopifyReducer,
    static: staticReducer,
  },
})

export default store;