import {
  SimpleGrid,
  Flex,
  Text,
  Image,
  Center,
  Box,
  useMediaQuery,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState, useEffect, useRef } from "react";

import { scroller } from "react-scroll";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCheckout, createCheckout } from "../../store/shopify";
import { getIndexOfInfo } from "../../store/static/selectors";
import { setIndexOfInfo } from "../../store/static";
import { InformationData } from "../../Constants/data";
import { shopify } from "../../Config/shopify";
import CloseButton from "../Common/CloseButton";
import ImageButton from "../Common/ImageButton";
import Store from "./Store";
import BboxForm from "./BboxForm";
import './style.scss';
import NewsLetters from "./NewsLetter";

const Information = ({
  donationFormData
}) => {
  const { collection, GoBtnImg } = InformationData;

  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [products, setProducts] = useState(null);
  const navigate = useNavigate();
  const ref = useRef();
  const dispatch = useDispatch();
  const sectionId = useSelector(getIndexOfInfo);

  const renderSubSection = () => {
    const data = sectionId === 0 ? donationFormData : null;
    switch (sectionId) {
      case 1:
        return <Store products={products} />;
      case 0:
        return <BboxForm formId={sectionId} data={data}/>; 
      case 2:
        return <NewsLetters />
      default:
        return null;
    }
  };
  const closeSubSection = () => {
    scroller.scrollTo("information", { smooth: true });
    setTimeout(() => {
      dispatch(setIndexOfInfo(-1));
      onClose();
    }, 1500);
  };

  const checkIfClickedOutside = (e) => {
    const sub_infos = ["Donate", "Store", "Newsletter", "donate-btn", "donate-img", "donate-text"];
    if (e.target.nodeName === 'HTML') return;
    if (e.target) {
      if (
        !ref.current.contains(e.target) &&
        sub_infos.every((el) => !e.target.classList.contains(el)) &&
        sectionId >= 0
      ) {
        closeSubSection();
      }
    }
  };

  const handleCheckout = () => {
    const checkoutId = localStorage.getItem("checkout_id");
    dispatch(checkoutId ? getCheckout(checkoutId) : createCheckout());
  };
  const getShopifyProducts = () => {
    shopify.product
      .fetchAll(250)
      .then((items) => {
        setProducts(
          items.filter((item) => item.productType === "Talking Bible"),
        );
      })
      .catch((err) => console.log("Shopify Error on getting products =>", err));
  };
  const goto = (id) => {
    switch (id) {
      case 0:
      case 1:
      case 2:
        scroller.scrollTo("sub-information", { smooth: true });                  
        break;
      default:
        break;
    }
    setTimeout(() => {
      dispatch(setIndexOfInfo(id));
    }, 1000);
  }

  useEffect(() => {
    handleCheckout();
    getShopifyProducts();
  }, []);

  // useEffect(() => {
  //   if (isOpen) {
       
  //   }
  // }, [isOpen]);

  useEffect(() => {
    if (!isOpen && sectionId >= 0 && sectionId <= 2) {
      if (products) onOpen()
      else {
        setTimeout(() => {
          onOpen();
        }, 1000);        
      }
    }
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [sectionId]);

  return (
    <Box ref={ref} position={"relative"}>
      <SimpleGrid
        id={"information"}
        width={{ base: "100%", sm: "100%", md: "100%", lg: "100%" }}
        minChildWidth={isMobile ? "100%" : ""}
        columns={[1, 2, 3, 3, 3]}
        boxShadow={"rgb(0 0 0 / 75%) 0px -10px 25px"}
        className="information-section"
      >
        {collection.map((item, index) => {
          return (
            <Flex
              className="information"
              key={`information-${index}`}
              backgroundColor={item.bgColor}
              flexDirection={"column"}
              opacity={isOpen ? (sectionId === index ? "1" : "0.4") : "1"}
              boxShadow={
                isOpen && sectionId === index ? "rgb(0 0 0) 0px 0px 20px" : ""
              }
              zIndex={isOpen && sectionId === index ? "1" : ""}
              transition={"all 0.3s"}
            >
              <Center>
                <Image
                  className="information-octagon"
                  src={item.octagonImg}
                  alt="Information Octagon"
                />
              </Center>

              <Box className="information-content" >
                <Text
                  className='information-text'
                  fontSize={["32px", "25px", "20px", "32px"]}
                  color={"white"}
                  fontFamily={'Roboto'}
                  dangerouslySetInnerHTML={{ __html: item.text }}
                >
                </Text>

                <Flex
                  flexDirection="row"
                  color={"white"}
                  alignItems={"center"}
                  borderRadius={4}
                  marginTop={10}
                  paddingX={1}
                  paddingY={2}
                  className='information-help'
                >
                  <Text
                    paddingLeft={10}
                    marginRight={5}
                    fontWeight={"medium"}
                    fontFamily={"Roboto"}
                  >
                    {item.help}
                  </Text>
                  <ImageButton
                    className="information-imgbtn"
                    alt="Go"
                    onClick={() => goto(index)}
                    bgImage={GoBtnImg}
                    bgHeight={"60px"}
                    bgWidth={"60px"}
                    h={"60px"}
                    w={"60px"}
                    pl={"4px"}
                    pt={"2px"}
                    pr={3}
                    pb={3}
                    fontSize={"20px"}
                    color={"white"}
                    text={"Go!"}
                  />
                </Flex>
              </Box>
            </Flex>
          );
        })}
      </SimpleGrid>
      <Box 
        id="sub-information"
        mt={"-185px"}
        pt={"185px"}
      ></Box>
      <Box                
        className="sub-information"
        display={!isOpen ? "none" : ""}
        fontFamily={"Roboto"}
      >
        {isOpen && (
          <>
            {renderSubSection()}
            <Flex
              justifyContent={"end"}
              paddingRight={"210px"}
              marginTop={"20px"}
              paddingBottom={"100px"}
            >
              <CloseButton
                iconColor={sectionId === 0 ? "#5F4E6A" : "#63B6AA"}
                onClick={closeSubSection}
              />
            </Flex>
          </>
        )}
      </Box>
    </Box>
  );
};

export default Information;
