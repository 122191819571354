import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from "@chakra-ui/react";

import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import LineItem from "./LineItem";
import { selectCheckoutData, selectLineItems, selectOpenCart } from "../../../store/shopify/selectors";
import { toggleCart } from "../../../store/shopify";

const Cart = () => {
  const lineItems = useSelector(selectLineItems);
  const checkoutData = useSelector(selectCheckoutData);
  const openCart = useSelector(selectOpenCart);
  const dispatch = useDispatch();
  
  const goToCheckout = () => {
    window.location.assign(checkoutData.webUrl);
  };
  const handleClose = () => {
    dispatch(toggleCart());
  }
  return (
    <>
      <Drawer isOpen={openCart} placement="right" onClose={() => handleClose()}>
        <DrawerOverlay/>
        <DrawerContent maxW={'400px'}>
          <DrawerCloseButton />
          <DrawerHeader fontFamily={'Roboto'} borderBottom={'1px solid #8080806b'}>Your Cart</DrawerHeader>

          <DrawerBody>
            {lineItems?.map(item => <LineItem data={item} key={item.id}/>)}
          </DrawerBody>

          <DrawerFooter>
            <Button fontFamily={'Roboto'} variant="outline" mr={3} onClick={() => handleClose()}>
              Cancel
            </Button>
            <Button fontFamily={'Roboto'} colorScheme="blue" onClick={()=> goToCheckout()}>Checkout</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default Cart;
