import React, { useEffect, useRef, useState } from 'react'
import { Flex, Image, Text } from '@chakra-ui/react'
import { ListenBibleData, playerButtons } from "../../../Constants/data";
import useAnalyticsEventTracker from '../../Common/useAnalyticsEventTracker';

const AudioPlayer = ({
  title, url, metaData, autoPlay, w, maxW, minW, simplePlayer, onEnded 
}) => {
  const { ListenIcon } = ListenBibleData;
  const audioPlayer = useRef();
  const [playing, setPlaying] = useState(false);
  const gaEventTracker = useAnalyticsEventTracker('Listened Chapter');

  const play = () => {
    
    if (url) {
      console.warn(`${metaData ?? title}`)
      gaEventTracker(`${metaData ?? title}`);
      setPlaying(true);
      audioPlayer.current.play();
    }
  };

  const pause = () => {
    if (url) {
      audioPlayer.current.pause();
    }
  };

  const onPlaying= () => {
    if (audioPlayer.current.paused) setPlaying(false);
    if (onEnded) {
      audioPlayer.current.currentTime === audioPlayer.current.duration && onEnded();
    }
  }
  
  return (
    simplePlayer 
    ? <>
      <Flex>
        <audio
          src={url}
          ref={audioPlayer}
        >
          Your browser does not support the
          <code>audio</code> element.
        </audio>
        <Text onClick={(!playing && !autoPlay) ? play : pause}>{title}</Text>
        {/* <Image paddingY={"10px"} w={[4, 4, "28px"]} src={playing ? playerButtons.pause : playerButtons.play} alt="Audio Player"></Image> */}
      </Flex>
    </>
    :  <>
      <Flex
        opacity={0.9}
        bg={"rgb(45,59,65)"}
        borderRadius="12px"
        width={w}
        maxW={maxW}
        minW={minW}
        paddingRight={3}
        paddingLeft={"23px"}
        justifyContent={"space-between"}
        flexDirection={"row"}
        alignItems={"center"}
        borderWidth={"3px"}
        borderColor={"rgb(88,149,181)"}
        cursor={'pointer'}
        onClick={((!playing && !autoPlay) ? play : pause)}
      >
        <audio
          src={url}
          ref={audioPlayer}
          onTimeUpdate={onPlaying}
          autoPlay={autoPlay}
        >
          Your browser does not support the
          <code>audio</code> element.
        </audio>
        <Text
          fontSize={"22px"}
          color={"white"}
          fontFamily={"Roboto"}
          fontWeight={"bold"}
        >
        {(!playing && !autoPlay) ? "LISTEN" : "PAUSE"}
        </Text>
        <Image
          paddingY={"10px"}
          w={[4, 4, "28px"]}
          src={ListenIcon}
          alt="Audio Player"
        ></Image>
      </Flex>
    </>
  )
}

export default AudioPlayer