import React, { useState, useEffect } from "react";
import test2 from "../../../assests/silver-green.png";
import { TriangleUpIcon } from '@chakra-ui/icons'
import Carousel from 'react-multi-carousel';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import 'react-multi-carousel/lib/styles.css';
import {
  Flex,
  Image,
  Text,
  SimpleGrid,
  useMediaQuery,
  Box,
  Center,
} from "@chakra-ui/react";
import { Link, scroller } from "react-scroll";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import './style.scss';
import { formatImageField } from "../../../utils/formatElements";
import { formatPath } from "../../../utils/formatPath";

const SubSectionItem = ({
  data,
  onClick
}) => {
  const { img, title } = data;
  return (
    <Link to={'testimonials'} smooth>
      <div
        onClick={() => onClick(data)} 
        style={{
          marginRight: '10px',
          marginLeft: '10px',
          position: 'relative',
          height: '260px',
          marginTop: '10px',
          filter: 'drop-shadow(4px -2px 8px rgba(0, 0, 0, 0.7))',
          cursor: 'pointer'
        }}
      >
        <Image
          bottom={'24px'}
          height={'225px'}
          width={'474px'}
          pos={'absolute'}
          src={img}
          objectFit={'cover'}
          alt="Sub section of Testimonials"
        />
        <Box
          padding={'10px'}
          position={'absolute'}
          bottom={'42px'}
          left={'35px'}
          backgroundColor={'#00000088'}
          minWidth={'80%'}
        >
          <Text 
            fontSize={'17px'} 
            fontWeight={'600'}
            fontFamily={'Roboto'}
            color={"white"}
            w={'185px'} 
            left={'50px'}
            lineHeight={'1.2'}
          >{title}</Text>
        </Box>
      </div>
    </Link>
  )
};

const Testimonials = ({elements}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isMobile] = useMediaQuery("(max-width: 768px)");

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3.5,
      partialVisibilityGutter: 30 
    },
    tablet: {
      breakpoint: { max: 1024, min: 656 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 655, min: 426 },
      items: 1.5,
    },
    smallMobile: {
      breakpoint: { max: 425, min: 0},
      items: 1
    }
  };
  const contentOptions = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: ({data: {target}}) => `<img style="padding: 10px 0px" src='${formatImageField(target)}' />`
    }
  }

  const formatTitle = (val) => {
    if (val && val.length > 80) return val.slice(0,80) + '...';
    else return val;
  }

  const closeSubsection = () => {
    scroller.scrollTo('testimonials', {smooth: true});
    setTimeout(() => {
      setShow(false);
    }, 1500);
  }

  useEffect(() => {
    const { param } = formatPath(location);
    if (elements) {
      if (param && param >= 0 && param < elements.length) {
        setSelectedItem(elements[param]);
        setShow(true);
      } else {
        setSelectedItem(elements[0])
      }
    }
  }, [elements])
  
  useEffect(() => {
    if (show) {
      scroller.scrollTo('sub-testimonials', {smooth: true});      
    }
  }, [show])
  
  return (
    <Flex flexDirection={"column"} position={'relative'} className="testimonials">
      <SimpleGrid
        id={"testimonials"}
        boxShadow={"rgb(0 0 0 / 75%) 0px -10px 25px"}
        style={{
          minHeight: "100%",
          objectFit: "contain",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${selectedItem?.img})`,
        }}
        width={{ base: "100%", sm: "100%", md: "100%", lg: "100%" }}
        height={["100%"]}
        pos='relative'
      >
        <Center>
          <Image
            marginTop={isMobile ? -30 : -6}
            alignSelf={"center"}
            pos="absolute"
            w={isMobile ? '75px' : '130px'}
            src={test2}
            alt="Changing Lives Stories"
          />
        </Center>
        <Flex
          paddingTop={[10, 0, 0]}
          position={"relative"}
          width={"100%"}
        >
          <Flex flex={2}></Flex>
          <Box flex={1}>
            <Flex mt={5} 
              flexDirection="column" 
              width={'fit-content'} 
              padding={'10px'} 
              backgroundColor={'#ffffff6e'} 
              marginTop={'75px'}
              marginRight={'20px'}
              className={'testimonial-content'}
            >
              <Text
                fontSize={{ base: "15px", md: "30px", lg: "35px" }}
                color={"#000000"}
                mt={4}
                mb={4}
                style={{
                  fontFamily: "Roboto",
                }}
              >
                TESTIMONIALS
              </Text>
              <Text
                id="testimonial-title"
                fontSize={{ base: "15px", md: "30px", lg: "42px" }}
                lineHeight={{ base: "18px", md: "33px", lg: "45px" }}
                width={!isMobile ? "321px" : ''}
                mt={isMobile ? "" : -4}
                color={"black"}
                style={{
                  fontFamily: "Roboto",
                  fontWeight: "bold",
                }}
              >
                {formatTitle(selectedItem?.title)}
              </Text>
              <Text
                width={"22ch"}
                maxH={'20ch'}
                overflow={'hidden'}
                marginTop={"30px"}
                fontSize={{ base: "15px", md: "20px", lg: "30px" }}
                lineHeight={{ base: "18px", md: "23px", lg: "33px" }}
                color={"black"}
                fontWeight={"medium"}
                fontFamily={'"Roboto"'}
                mb={'25px'}
                dangerouslySetInnerHTML={{ __html: documentToHtmlString(selectedItem?.intro) }}
              >
              </Text>
                <Text
                  cursor={'pointer'}
                  fontSize={{ base: "15px", md: "30px", lg: "30px" }}
                  lineHeight={{ base: "15px", md: "40px", lg: "40px" }}
                  color={"black"}
                  fontWeight={"bold"}
                  onClick={() => setShow(!show)}
                  fontFamily={"Roboto"}
                >
                  READ+
                </Text>
            </Flex>
          </Box>
        </Flex>
        {
          elements && (
            <Box className="more-stories-showed" display={show ? 'none' : ''}>
              <Text 
                fontSize={'24px'} 
                marginBottom={'10px'} 
                fontFamily={'Roboto'} 
                fontWeight={'bold'} 
                color={'#fff'}
              >More Stories</Text>
              <Carousel responsive={responsive} autoPlay infinite>
                {elements.map((item, index) => (
                  <SubSectionItem key={index} data={item} onClick={(val) => setSelectedItem(val)}/>
                ))}
              </Carousel>
              <Box cursor={'pointer'} onClick={()=>navigate('/testimonies')}>
                <Text 
                  fontSize={'24px'} 
                  marginBottom={'10px'} 
                  fontFamily={'Roboto'} 
                  fontWeight={'bold'} 
                  color={'white'}
                  textAlign={'right'}
                >Read all the Stories</Text>
              </Box>  
            </Box>
          )
        }
      </SimpleGrid>
      {
          elements && (
            <Box className="more-stories-showed-mobile" display={show ? 'none' : ''}>
              <Text 
                fontSize={'24px'} 
                marginTop={'25px'} 
                paddingX={isMobile ? 5 : '70px'}
                fontFamily={'Roboto'} 
                fontWeight={'bold'} 
                color={'#000'}
              >More Stories</Text>
              <Carousel responsive={responsive} autoPlay infinite>
                {elements.map((item, index) => (
                  <SubSectionItem key={index} data={item} onClick={(val) => setSelectedItem(val)}/>
                ))}
              </Carousel>
              <Box cursor={'pointer'} onClick={()=>navigate('/testimonies')}>
                <Text 
                  fontSize={'24px'} 
                  paddingX={isMobile ? 5 : '70px'}
                  marginBottom={'50px'} 
                  fontFamily={'Roboto'} 
                  fontWeight={'bold'} 
                  color={'#000'}
                  textAlign={'right'}
                >Read all the Stories</Text>
              </Box>
            </Box>
          )
        }
      {show && <>
        <Box id="sub-testimonials" w={'100%'} px={'20'} pb={10} pt={'250px'} mt={'-200px'}>
          <Text 
            lineHeight={8} 
            fontSize={20} 
            fontFamily={"Roboto"}
            style={{
              columnFill: 'auto',
              columnGap: '100px'
            }}
            className="sub-testimonials"
            dangerouslySetInnerHTML={{ __html: documentToHtmlString(selectedItem?.content, contentOptions) }}
          >
          </Text>
          <Helmet>
            <title>{selectedItem?.metaTitle}</title>
            <meta name="description" content={selectedItem?.metaDescription} />
            <meta name="og:description" content={selectedItem?.metaOgDescription} />
            <meta name="og:title" content={selectedItem?.metaTitle} />
            <meta name="twitter:description" content={selectedItem?.metaTwitterDescription} />
          </Helmet>
        </Box>
        <Box className="more-stories">
          <Text 
            fontSize={'24px'} 
            marginBottom={'10px'} 
            fontFamily={'Roboto'} 
            fontWeight={'bold'} 
          >More Stories</Text>
          <Carousel responsive={responsive} >
            {elements.map((item, index) => (
              <SubSectionItem key={index} data={item} onClick={(val) => setSelectedItem(val)}/>
            ))}
          </Carousel>
          <Box cursor={'pointer'} onClick={()=>navigate('/testimonies')}>
            <Text 
              fontSize={'24px'} 
              marginBottom={'10px'} 
              fontFamily={'Roboto'} 
              fontWeight={'bold'} 
              color={'white'}
              textAlign={'right'}
            >Read all the Stories</Text>
          </Box>
        </Box>
        <Flex justifyContent={'end'} paddingRight={'100px'} marginTop={'20px'} paddingBottom={'100px'}>
          <Box 
            textAlign={'center'} 
            cursor={'pointer'} 
            padding={'5px'} 
            borderWidth={'1px'} 
            borderStyle={'solid'} 
            borderRadius={'9px'} 
            borderColor={'transparent'}
            _active={{
              borderColor: '#63B6AA'
            }}
            onClick={() => closeSubsection()}
          >
            <TriangleUpIcon color={'#63B6AA'} w={6} h={6}/>
            <Text
              fontFamily={"Roboto"} 
              fontSize={"17px"}
              fontWeight={'bold'}
            >
              Close
            </Text>
          </Box>
        </Flex>
      </>}
    </Flex>
  );
};
export default Testimonials;
