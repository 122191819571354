import React, { useEffect, useState } from 'react'
import { Box, Image, Text } from "@chakra-ui/react";
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { contentful } from "../Config/contentful";
import { formatCareers, formatOctagons, formatRichTextStyle } from "../utils/formatElements";
import Header from '../Components/Header/index';
import Footer from '../Components/Footer/index';
import Hexagone from '../Components/Common/Hexagone';

const Careers = () => {
  const [headerImage, setHeaderImage] = useState(null);
  const [careersData, setCareersData] = useState(null);
  const [hexagonData, setHexagonData] = useState(null);

  const getContentfulData = async () => {
    const response = await contentful.getEntries("");
    if(!response.items) return;

    const octagonCollection = [];
    const careersCollection = [];
    await response.items.forEach((item) => {
      const {sys: {contentType: {sys: {id}}}} = item;

      // Get Collection of elements
      switch (id.toLowerCase()) {
        case "octagondetail":
          octagonCollection.push(item);
          break;
        case "careers":
          careersCollection.push(item);          
          break;
        default:
          break;
      }
    });
    
    setHexagonData(formatOctagons(octagonCollection));
    setCareersData(formatRichTextStyle(documentToHtmlString(formatCareers(careersCollection[0])?.content)));
    setHeaderImage(formatCareers(careersCollection[0])?.headerImage);
  }

  useEffect( () => {
    getContentfulData();
  }, []);

  return (
    <>
      <Header />
      <Image 
        w={'100%'}
        h={'50vh'}
        maxH={'700px'}
        src={headerImage ?? ''}
        objectFit={'cover'}
        alt="Header"
      />
      <Box id={'content'} pb={'300px'} >
        <Text
          marginTop={"30px"}
          fontSize={{ base: "15px", md: "20px", lg: "30px" }}
          lineHeight={{ base: "18px", md: "23px", lg: "33px" }}
          color={"black"}
          fontWeight={"medium"}
          fontFamily={'"Roboto"'}
          mb={'25px'}
          maxW={'1130px'}
          px={'150px'}
          dangerouslySetInnerHTML={{ __html: careersData }}
        ></Text>
      </Box>
      <Hexagone elements={hexagonData} pb={"200px"} dropShadow={'drop-shadow(rgba(0, 0, 0, 0.9) 0px 0px 15px)'} />
      <Footer />
    </>
  )
}

export default Careers