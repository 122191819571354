import { Box, Center } from "@chakra-ui/layout";
import { Flex, Text, Image, Link, useMediaQuery, OrderedList, ListItem, useDisclosure } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { scroller } from 'react-scroll';
import topImage from "../../assests/g4.png";
import bibles from "../../assests/Layer_0_1_.png";
import { footerImage, footerTopImage } from "../../Constants/data";
import { setIndexOfInfo } from "../../store/static";
import { formatPath } from "../../utils/formatPath";
import ContactUsForm from '../Common/Popup/ContactUs';
import './style.scss';

const Footer = () => {
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {isOpen, onOpen, onClose} = useDisclosure();
  
  const goto = (page, sectionId) => {
    navigate(page);
    if (sectionId !== 'Store') {
      setTimeout(() => {
        scroller.scrollTo(sectionId, { smooth: true });
      }, 1000);
    } else {
      dispatch(setIndexOfInfo(1));
    }
  }

  useEffect(() => {
    if (formatPath(location).path === 'contact') {
      onOpen();
    }
  }, [])
  

  return (
    <Box
      style={{
        backgroundColor: '#0E89AB',
        boxShadow: "0px -10px 25px rgba(0, 0, 0, 0.75)"
      }}
      width={{ base: "100%", sm: "100%", md: "100%", lg: "100%" }}
      height={'auto'}
      position={'relative'}
      className={'footer-bar'}
    >
      <Flex justifyContent={"center"}>
        <Flex 
          flexDirection={"column"} 
          position={"absolute"}
          className="footer-octagon"
        >
            <Image
              alignSelf={"center"}
              src={topImage}
              alt="Footer top image"
            />
            <OrderedList 
              textAlign='center'
              fontSize={isMobile ? "12px" : "20px"}
              fontWeight={"bold"}
              fontFamily='Roboto'
              color='white'
              lineHeight='28px'
              w={"170px"}
              ml={'0px'}
              className={'footer-menu-section'}
            >
              <ListItem cursor={'pointer'} onClick={() => goto('/', 'listen')}>Listen Online</ListItem>
              <ListItem cursor={'pointer'} onClick={() => goto('/', 'Store')}>Get a Talking Bible</ListItem>
              <ListItem cursor={'pointer'} onClick={onOpen} >Contact Us</ListItem>
              <ListItem cursor={'pointer'} onClick={() => goto('/career', 'content')}>Careers</ListItem>
              <ListItem cursor={'pointer'} onClick={() => goto('/privacy-policy', 'content')}>Privacy Policy</ListItem>
            </OrderedList>
          </Flex>
      </Flex>
          
      <Flex
        paddingX={"100px"}
        paddingTop={"15px"}
        paddingBottom={"30px"}
        height={"351px"}
        className={'footer-contents'}
      >
        <Center
          justifyContent={"flex-start"}
          alignSelf={"end"}
          className="col"
          maxW={"fit-content"}
        >
          <Flex
            alignSelf={"flex-end"}
            height={"100%"}
            flexDirection={"column"}
            gap={'17px'}
          >
            <Flex
              alignSelf={"flex-start"}
              height={"100%"}
              flexDirection={"column"}
              gap={'17px'}
            >
              <Text
                fontWeight={'bold'}
                fontSize={{ base: '12px', md: '15px', lg: '22px', }}
                fontFamily={"Roboto"}
                lineHeight={{ base: '9px', md: '14px', lg: '18px', }}
                w={["96px", "96px", "172px", "172px"]}
                color={"white"}
                marginTop={12}
              >Follow Us!</Text>
              <Flex width={"100%"} gap={'11px'} className="footer-top-image">
                {footerTopImage.map((item, index) => {
                  return (
                    <Link key={index} isExternal href={item.link} _focus={{boxShadow: 'unset'}}>
                      <Image
                        h={"85px"}
                        w={""}                      
                        src={item.img}
                        style={{ imageRendering: 'smooth' }}
                        objectFit={'contain'}
                        alt="Social icon"
                      ></Image>
                    </Link>
                  );
                })}
              </Flex>
            </Flex>
            <Text
              fontWeight={'400'}
              fontSize={{ base: '9px', md: '12px', lg: '14px', }}
              fontFamily={"Roboto"}
              lineHeight={{ base: '9px', md: '14px', lg: '18px', }}
              w={["96px", "96px", "172px", "172px"]}
              color={"white"}
              marginTop={'20px'}
            >
              Talking Bibles International 419
              East Grand Avenue
              Escondido, CA 92025 USA
            </Text>
            <Flex width={"100%"} gap={'11px'} className="footer-image">
              {footerImage.map((item, index) => {
                return (
                  <Link key={index} isExternal href={item.link} _focus={{boxShadow: 'unset'}}>
                    <Image
                      h={"85px"}
                      w={""}                      
                      src={item.img}
                      style={{ imageRendering: 'smooth' }}
                      objectFit={'contain'}
                      alt="footer images"
                    ></Image>
                  </Link>
                );
              })}
            </Flex>
          </Flex>
        </Center>
        <Center
          flexDirection={"column"}
          justifyContent={"end"}
          className="col"
        >
          <Flex
            marginTop={isMobile ? 6 : 0}
            alignItems={"flex-end"}
          >
            <Center paddingX={'20px'}>
              <Text
                color={"white"}
                w={"100%"}
                fontSize={["9px", "9px", "13px", "13px"]}
                lineHeight={"18px"}
                fontFamily={'Roboto'}
                marginTop={'50px'}
              >
                © 2001–2022 Talking Bibles International. Talking Bible® is a
                registered trademark of Talking Books International, Inc. dba
                TB2U.
              </Text>
            </Center>
          </Flex>
        </Center>
        <Center
          className="col footer-logo"
          justifyContent={"flex-end"}
          maxW={"fit-content"}
        >
            <Image
              alignSelf={'flex-end'}
              src={bibles}
              marginTop={5}
              objectFit={'contain'}
              cursor={'pointer'}
              onClick={() => goto('/', 'root')}
              alt="Footer logo"
            />
        </Center>
      </Flex>
      <ContactUsForm isOpen={isOpen} onClose={onClose}/>
    </Box>
  );
};

export default Footer;
