import { S3_config } from "../Config/aws";
import { formatUrl } from "@aws-sdk/util-format-url";
import { HttpRequest } from "@aws-sdk/protocol-http";
import { S3RequestPresigner } from "@aws-sdk/s3-request-presigner";
import { parseUrl } from "@aws-sdk/url-parser";
import { Sha256 } from "@aws-crypto/sha256-browser";

const audioList = ['mp3', 'wav', 'aif', 'mp4', 'mid']

const formatBookName = (val) => {
  const a = val.split('_');
  if (a.length < 2) return a[0];
  else {
    const s = val.replace(a[0] + '_', '');
    const t = s.split('-');
    return t.length < 2 ? t[0] : s.replace(t[0]+'-', '') + '-' + t[0];
  }
}

const formatContent = (val, url) => {
  const els = val.split('/');
  const tmpLang = els[1].replaceAll('_', ' ').split('-');
  const tmpChapter = els[els.length - 1].slice(0, -4).split('_');

  return {
    lang: tmpLang.length > 0 ? tmpLang[0] : '',
    book: formatBookName(els[3]),
    testament: els[2],
    chapter: tmpChapter.length > 0 ? tmpChapter[tmpChapter.length - 1] : '',
    url
  };
}

const formatS3Contents =  (contents) => {
  const { region, credentials, CloudFrontDomainName} = S3_config;
  const formattedContents = Promise.all([
    ...contents
      .filter(content => 
        content.Key && content.Key.split('/').length === 5 && (audioList.some(s => content.Key.endsWith('.' + s)) || content.Key.endsWith('.txt') )  
      )
      .map(async (content) => {
        const s3ObjectUrl = parseUrl(`https://${CloudFrontDomainName}/${content.Key}`);
        const preSigner = new S3RequestPresigner({
            credentials,
            region,
            sha256: Sha256 // In browsers
        });
        // Create a GET request from S3 url.
        const url = await formatUrl( await preSigner.presign(new HttpRequest(s3ObjectUrl), { expiresIn: S3_config.expiresIn }));
        return Promise.resolve ({...formatContent(content.Key, url)});
      })
  ]);

  return formattedContents;
}

export { formatS3Contents };