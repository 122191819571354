import { Center, Flex, Text } from '@chakra-ui/react'
import React from 'react'

const ImageButton = ({
  className, bgImage, bgWidth, bgHeight, h, w, text, pr, pb, pl, pt, py, px, color, fontSize, onClick
}) => {
  return (
    <Flex
      className={className}
      onClick={() => onClick()}
      style={{
        height: bgHeight,
        width: bgWidth,
        justifyContent: "center",
        alignItems: "center",
        backgroundPosition: "center",
        backgroundSize: "cover",
        cursor: "pointer",
        backgroundRepeat: "no-repeat",
        backgroundImage: `url(${bgImage})`,
      }}
      filter={'drop-shadow(rgba(0, 0, 0, 0.75) 7px 7px 4px)'}
    >
      <Center height={h} width={w}>
        <Text
          cursor={"pointer"}
          fontWeight={"bold"}
          fontSize={fontSize}
          paddingX={px}
          paddingRight={pr}
          paddingBottom={pb}
          pl={pl}
          pt={pt}
          py={py}
          color={color}
          fontFamily={"Roboto"}
        >
          {text}
        </Text>
      </Center>
    </Flex>
  )
}

export default ImageButton
