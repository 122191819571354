import { Box, Grid, GridItem, Heading, Image, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { scroller } from 'react-scroll';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { useNavigate, useParams  } from 'react-router-dom';

import Hexagone from '../Components/Common/Hexagone';
import Footer from '../Components/Footer';
import Header from '../Components/Header';
import { contentful } from '../Config/contentful';

import { formatTestimonialHeader, formatTestimonials, formatOctagons } from "../utils/formatElements";

const Testimonies = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [testimonialData, setTestimonialData] = useState();
  const [testimonialHeader, setTestimonialHeader] = useState();
  const [hexagonData, setHexagonData] = useState(null);

  const getContentfulData = async () => {
    const response = await contentful.getEntries("");
    if(!response.items) return;

    const testimonialCollection = [];
    const octagonCollection = [];
    await response.items.forEach((item) => {
      const {sys: {contentType: {sys: {id}}}} = item;

      // Get Collection of elements
      switch (id) {
        case 'testimonialsDetail':
          testimonialCollection.push(item);
          break;
        case 'testimonials':
          setTestimonialHeader(formatTestimonialHeader(item));
          break;
        case 'octagonDetail':
          octagonCollection.push(item);
          break;
        default:
          break;
      }   
    });
    setTestimonialData(formatTestimonials(testimonialCollection));
    setHexagonData(formatOctagons(octagonCollection));
  }

  useEffect( () => {
    scroller.scrollTo('content');
    getContentfulData();
  }, []);

  return (
    <>
      <Header />
      {!id && (
        <Image 
          w={'100%'}
          h={'50vh'}
          maxH={'700px'}
          src={testimonialHeader?.headerImage ?? ''}
          objectFit={'cover'}
          alt="Header"
        />
      )}
      <Box id={'content'} pb={{base: '150px', md: '150px', lg: '300px'}} pt={'70px'} className='container' fontFamily={"Roboto"}>
        {!id && (
          <Text 
            overflow={'hidden'}
            fontSize={{ base: "30px", md: "45px", lg: "55px" }}
            lineHeight={{ base: "55px", md: "65px", lg: "75px" }}
            color={"black"}
            fontWeight={'bold'}
            fontFamily={'"Roboto"'}
            mb={'25px'}
          >Changed Life Stories</Text>
        )}
        {
            !id 
            ? <Grid
              gridTemplateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)",  lg: "repeat(2, 1fr)", xl: "repeat(3, 1fr)"}}
              columnGap='20px'
              rowGap='20px'
            >
              {testimonialData?.map((s, i) => (
                <GridItem key={'testimonial-' + i} onClick={()=>navigate(`/testimonies/${i.toString()}`)} cursor='pointer'>
                  <Box>
                    <Image objectFit={'cover'} width='420px' height={'250px'} src={s.img} alt={s.title}/>
                    <Text 
                      overflow={'hidden'}
                      fontSize={{ base: "15px", md: "20px", lg: "30px" }}
                      lineHeight={{ base: "18px", md: "23px", lg: "33px" }}
                      color={"black"}
                      fontWeight={'bold'}
                      fontFamily={'"Roboto"'}
                      mt={'25px'}
                    >{s.title}</Text>
                    <Text
                      maxH={'8ch'}
                      overflow={'hidden'}
                      marginTop={"30px"}
                      fontSize={{ base: "15px", md: "15px", lg: "20px" }}
                      lineHeight={{ base: "18px", md: "20px", lg: "30px" }}
                      color={"black"}
                      fontWeight={"medium"}
                      fontFamily={'"Roboto"'}
                      mb={'25px'}
                      dangerouslySetInnerHTML={{ __html: documentToHtmlString(s.intro) }}
                    ></Text>
                    <Text
                      cursor={'pointer'}
                      fontSize={{ base: "15px", md: "25px", lg: "25px" }}
                      lineHeight={{ base: "15px", md: "40px", lg: "40px" }}
                      color={"black"}
                      fontWeight={"bold"}
                      fontFamily={"Roboto"}
                      mt='10px'
                      mb='30px'
                    >
                      READ+
                    </Text>
                  </Box>
                </GridItem>
              ))}
            </Grid>
            :<Grid>
              <GridItem>
                <Box>
                  <Image w={'100%'} src={testimonialData?.[id].img} alt={testimonialData?.[id].title}/>
                  <Heading
                    mt={'30px'}
                    fontStyle={'normal'}
                    fontSize={"32px"}
                    lineHeight={"33px"}
                    fontFamily={'Roboto'}
                    fontWeight={'bold'}
                  >{testimonialData?.[id].title}</Heading>
                  <Text
                    overflow={'hidden'}
                    marginTop={"30px"}
                    fontSize={{ base: "15px", md: "20px", lg: "20px" }}
                    lineHeight={{ base: "18px", md: "23px", lg: "33px" }}
                    color={"black"}
                    fontWeight={"medium"}
                    fontFamily={'"Roboto"'}
                    mb={'25px'}
                    dangerouslySetInnerHTML={{ __html: documentToHtmlString(testimonialData?.[id].content) }}
                  />
                </Box>
            </GridItem>
            </Grid>
        }
      </Box>
      <Hexagone elements={hexagonData} pb={"200px"} dropShadow={'drop-shadow(rgba(0, 0, 0, 0.9) 0px 0px 15px)'} />
      <Footer />
    </>
  )
}

export default Testimonies