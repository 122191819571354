import React from "react";
import {
  Flex,
  Text,
  Image,
  Heading,
  Center,
  useMediaQuery,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import videopng from "../../assests/seagreen-purple.png";
import VideoCarousel from "../Common/VideoCarousel";

const VideoInformation = ({elements}) => {
  const [isMobile] = useMediaQuery("(max-width: 768px)");

  return (
    <Flex
      id="videos"
      paddingX={{ base: "20px", md: '70px', lg: '70px', xl: '70px' }}
      width={{ base: "100%", sm: "100%", md: "100%", lg: "100%" }}
      backgroundColor={"rgb(120,180,171)"}
      flexDirection={"column"}
      boxShadow={"rgb(0 0 0 / 75%) 0px -10px 25px"}
      position={'relative'}
    >
      <Center>
        <Image
          width={!isMobile? '130px':'75px'}
          marginTop={isMobile ? '-45px' : -20}
          src={videopng}
          alt="video"
        />
      </Center>
        <Grid
          className="video-information"
          templateColumns={{md: "repeat(5, 1fr)", lg: "repeat(5, 1fr)"}}
          gap={16}
          pb={'80px'}
          templateRows="1"
        >
          <GridItem rowSpan={1} colSpan={2} minW={'280px'} marginBottom={!isMobile && '100px'}>
            <Flex flexDirection={"column"}>
              <Heading
                color={"white"}
                fontSize={{ base: "24px", md: "24px", lg: "24px", xl: "44px" }}
                lineHeight={{ base: "24px", md: "24px", lg: "24px", xl: "44px" }}
                fontWeight={"bold"}
                fontFamily={"Roboto"}
                maxW={'353px'}
                minW={'303px'}
              >
                {elements?.title}
              </Heading>
              <Text
                marginTop={"22px"}
                fontSize={{ sm: "12px", base: "10px", md: "15px", lg: "15px", xl: '20px' }}
                color={"white"}
                w={["", "", "280px", "280px", "400px"]}
                fontFamily={"Roboto"}
              >
                {elements?.text}
                <br/>
                <span style={{ fontWeight: "bolder" }}>
                  {elements?.subTitle}
                </span>
              </Text>
            </Flex>
          </GridItem>
          <GridItem colSpan={3} marginBottom={"155px"} minW={'280px'}>
            <Center alignItems={"baseline"} >
              <Flex
                width={!isMobile ? "calc(100vw/2)" : '90%'}
                height={!isMobile ? 'calc(100vw/2 * 0.568)' : 'calc(90vw * 0.568)'}
                className="video"
                style={{
                  paddingBottom: '40px',
                  alignItems: "center",
                  backgroundSize: "contain",
                  flexDirection: 'column'
                }}
              >
                <VideoCarousel videos={elements?.videos} />
              </Flex>
            </Center>
          </GridItem>
        </Grid>
    </Flex>
  );
};

export default VideoInformation;
