import V1 from "../assests/v1.png";
import V2 from "../assests/v2.png";
import V4 from "../assests/v4.png";
import V5 from "../assests/v5.png";
import V7 from "../assests/v6.png";
import V8 from "../assests/v8.png";
import V9 from "../assests/v9.png";
import image1 from "../assests/image1.png";
import InfoSec from "../assests/seagreen-blue.png";
import InfoFirst from "../assests/blue-navy.png";
import InfoThird from "../assests/purple-yellow.png";
import GoBtnImg from "../assests/bgImage.png";

import Thumbnail from "../assests/header.png";
import Frame from "../assests/octa1.png";
import Cart from "../assests/shopping-cart.png"

import image4 from "../assests/image4.png";
import aboutus3 from "../assests/aboutus3.png";
import footImage1 from "../assests/Layer_0_6_.png";
import footImage2 from "../assests/Layer_0_3_.png";
import footerImage3 from "../assests/Layer_0_4_.png";

import facebookImg from "../assests/facebook.svg";
import twitterImg from "../assests/twitter.svg";
import instagramImg from "../assests/instagram.svg";

import sliderImage1 from "../assests/topb1.jpg";
import hexaBackgroundImg from "../assests/newHexa.png";
import hexaImg1 from "../assests/m1.png";
import hexaImg2 from "../assests/topImage.png";
import hexaImg3 from "../assests/men.png";
import ts from "../assests/ts.png";

import ProductImg from "../assests/bb33.jpg";
import StoreBackground from "../assests/sceenshot.png";

import L2 from "../assests/l2.png";
import vectorImage from "../assests/black-blue.png";
import HeadPhone from "../assests/headphone.jpg";
import ListenIcon from "../assests/listen.png";
import playGon from "../assests/p2.png";

import playImg from "../assests/audio/play.png";
import pauseImg from "../assests/audio/pause.png";

const navData = [
  {
    title: "Home",
    id: "carousel-section",
    color: "rgb(253,249,248)",
    icon: V2,
  },
  {
    title: "About us",
    id: "about-us",
    color: "rgb(253,249,248)",
    icon: V1,
  },
  {
    title: "Testimonials",
    color: "rgb(253,249,248)",
    icon: V4,
    id: "testimonials",
  },
  {
    title: "Listen ",
    id: "listen",
    icon: V7,
  },
  {
    title: "Newsletter",
    icon: V5,
    id: "sub-information",
  },
  {
    title: "Store",
    icon: V8,
    id: "sub-information",
  },
   {
    title: "Donate",
    icon: V9,
    id: "sub-information",
  },
];

const InformationData = {
  GoBtnImg,
  collection: [
    {
      title: ` `,
      help: "Help us to do much more",
      bgColor: "rgb(92,78,104)",
      octagonImg: InfoFirst,
      text: "<b>DONATE</b> AND <br>BRING THE AUDIBLE <br>WORD OF GOD <br>TO MILLIONS.",
    },
    {
      title: "",
      octagonImg: InfoSec,  
      help: "When you buy you’re helping",
      bgColor: "rgb(119,179,172)",
      text: "<b>VISIT OUR STORE</b> <br>AND GET YOUR <br>OWN TALKING <br>BIBLE.",
    },
    {
      title: "",
      text: "<b>READ OUR <br>LAST NEWSLETTER</b> <br>SUBSCRIBE AND ENJOY<br>OUR COMMUNITY.",
      octagonImg: InfoThird,
      help: "Write your email here ",
      bgColor: "rgb(238,201,118)",
    },
  ]
};

const DonationData = {
  billingAddress: {
    title: 'Billing Address',
    checkbox: 'Make this gift on behalf of an organization',
  },
  paymentDetails: {
    title: 'Payment Details',
  }
}
const StoreData = {
  title: "English (KJV) <br/>Talking Bible® <br/>Battery Talking Bible",
  text: "is designed for rugged use by non-readers around the world. It has no screen or display, so it is perfect for computer novices and the visually impaired.",
  price: "49.99",
  ProductImg: ProductImg,
  subText: `<b>The Talking Bible®</b><br/>These sturdy, versatile audio Bible players are built to meet the requirements of the mission field. 
            Talking Bibles International is sending out these players into communities of oral learners, where access to God's spoken Word is allowing local non-readers to become church leaders, pastors, and missionaries to their people.
            Other Christians are finding their own uses for our simple, reliable audio Scripture player.
            <br/><br/>Purchase Talking Bibles here for yourself, your friends, and your organization. 
            A portion of the purchase price of every Talking Bible is donated to help support Bible distribution among non-readers around the world.
            For questions about large orders or out-of-stock items, please contact TB2U Support.
            <br/><br/><b>Easy to Use</b><br/>Two buttons move between books. Two buttons move between chapters.
            <br/><br/>A single dial controls both volume and power. The standard auxiliary jack connects to headphones and speakers.
            <br/><br/>The Talking Bible resumes playing in the last chapter you listened to. 
            <br/><br/><b>Solar or Batteries?</b><br/>The Talking Bible comes with either a built-in solar panel or a battery compartment. 
            Your choice! <br/><br/>The Solar Talking Bible plays for two hours when fully charged. 
            <br/><br/>The Battery Talking Bible takes three standard AAs, and runs for hours!`,
  StoreBackground: StoreBackground
}

const aboutUs = [
  { title: "WHAT WE DO", image: image1 },
  {
    first: "HOW",
    second: "ITS WORK ?",
    title: "HOW  ITS WORK ?",
    image: image4,
  },
  { title: "31 YEAR OF MINISTRY", image: aboutus3 },
];

const sliderData = [
  {
    img: sliderImage1,
    title: "These 103 little dots in front of the Bible are a symbol of equal opportunities to everyone.",
  },
  {
    img: sliderImage1,
    title: "These 103 little dots in front of the Bible are a symbol of equal opportunities to everyone.",
  },
  {
    img: sliderImage1,
    title: ',~! #ajc ll dots 9 front (! ,bi# >e a symbol ( equal opportunities 6"ey”o4',
  }
];

const hexagoneData = [
  {
    border: hexaBackgroundImg,
    img: hexaImg1,
    amount: '700+',
    title: 'millions',
    intro: 'There are <b>more than 700 million non-readers</b> in the world. They will never be able to read of Jesus.'
  },
  {
    border: hexaBackgroundImg,
    img: hexaImg2,
    amount: '350+',
    title: 'millions',
    intro: 'There are <b>more than 350 million people</b> in the world with some <b>visual disability.</b> They need to audible Word of God.'
  },
  {
    border: hexaBackgroundImg,
    img: hexaImg3,
    amount: '2,000+',
    title: 'languages',
    intro: 'So that no one misses the message of the Gospel, we provide <b>the Bible in more than 2,000 languages.</b>'
  }
]

const TextimonialsData = [
    {
      img: ts,
      title: 'OPSE BDANE wants to draw others to Christ.',
      text:  `
        Opse received a Talking Bible and began to listen. She shared it with her family. 
        Her husband and four children became believers after listening to the Talking Bible.
        Since she cannot read, she listens to the Talking Bible as often as she can. 
        She says, “I now understand how Jesus is LORD. He is alive and is the ONE WAY.”
        When asked what has changed after hearing the Talking Bible, her excitement grew. 
        Bouncing her folded hands up and down in her lap, she says, “After hearing the Bible, I love Christ Jesus and God more and more. I have love in my heart to live forever with HIM."
        She also wants to draw others to Christ. 
        Her bright smile lights up the room as she says, “I am hoping and believing many will come to Christ through listening to God’s Word.” 
      
      `
    },
    {
      img: image4,
      title: 'Título del testimonio hasta cuatro líneas',
      text:  `
        Opse received a Talking Bible and began to listen. She shared it with her family. 
        Her husband and four children became believers after listening to the Talking Bible.
        Since she cannot read, she listens to the Talking Bible as often as she can. 
        She says, “I now understand how Jesus is LORD. He is alive and is the ONE WAY.”
        When asked what has changed after hearing the Talking Bible, her excitement grew. 
        Bouncing her folded hands up and down in her lap, she says, “After hearing the Bible, I love Christ Jesus and God more and more. I have love in my heart to live forever with HIM."
        She also wants to draw others to Christ. 
        Her bright smile lights up the room as she says, “I am hoping and believing many will come to Christ through listening to God’s Word.” 
        Her love and enthusiasm are contagious!
        Your gift will allow new believers like Opse share God’s love and teachings with those around them. 
        What an incredible blessing!
      `
    },
    {
      img: aboutus3,
      title: 'Título del testimonio hasta cuatro líneas',
      text:  `
        Opse received a Talking Bible and began to listen. She shared it with her family. 
        Her husband and four children became believers after listening to the Talking Bible.
        Since she cannot read, she listens to the Talking Bible as often as she can. 
        She says, “I now understand how Jesus is LORD. He is alive and is the ONE WAY.”
        When asked what has changed after hearing the Talking Bible, her excitement grew. 
        Bouncing her folded hands up and down in her lap, she says, “After hearing the Bible, I love Christ Jesus and God more and more. I have love in my heart to live forever with HIM."
        She also wants to draw others to Christ. 
        Her bright smile lights up the room as she says, “I am hoping and believing many will come to Christ through listening to God’s Word.” 
        Her love and enthusiasm are contagious!
        Your gift will allow new believers like Opse share God’s love and teachings with those around them. 
        What an incredible blessing!
      `
    },
    {
      img: image1,
      title: 'Título del testimonio hasta cuatro líneas',
      text:  `
        Opse received a Talking Bible and began to listen. She shared it with her family. 
        Her husband and four children became believers after listening to the Talking Bible.
        Since she cannot read, she listens to the Talking Bible as often as she can. 
        She says, “I now understand how Jesus is LORD. He is alive and is the ONE WAY.”
        When asked what has changed after hearing the Talking Bible, her excitement grew. 
        Bouncing her folded hands up and down in her lap, she says, “After hearing the Bible, I love Christ Jesus and God more and more. I have love in my heart to live forever with HIM."
        She also wants to draw others to Christ. 
        Her bright smile lights up the room as she says, “I am hoping and believing many will come to Christ through listening to God’s Word.” 
        Her love and enthusiasm are contagious!
        Your gift will allow new believers like Opse share God’s love and teachings with those around them. 
        What an incredible blessing!
      `
    },
];

const ListenBibleData = {
  L2Img: L2,
  vectorImg: vectorImage,
  HeadPhoneImg: HeadPhone,
  ListenIcon: ListenIcon,
  playGonImg: playGon,
  text: `The purpose of this free space is for people of all religions to
  listen to the Bible in their language, and by sharing or donating
  it, they collaborate with millions of people who, even if they
  wanted to read it, cannot. We appreciate the prayers and support
  of the listeners. On this site you will find information about how
  to contribute to our mission and help send Talking Bibles to the
  illiterate and disabled around the world! Mark S. Hoekstra,
  President`
};

const TalkingBibleData = {
  title: `WHY A TALKING BIBLE?`,
  text: `
  By our estimation, there are over 1 billion people in the world who cannot read the Bible in their heart language. These are the men, women  and children who need the spoken Word.
  `,
  youtubeUrl: 'https://www.youtube-nocookie.com/embed/vGRnWCaSktg?modestbranding=1&showinfo=0&rel=0'
}

const footerImage = [
  {
    img: footImage1,
    link: 'http://www.ecfa.org/MemberProfile.aspx?ID=33550'
  },
  {
    img: footerImage3,
    link: 'https://www.guidestar.org/profile/33-0361583'
  },
  {
    img: footImage2,
    link: 'https://www.barnabasfoundation.com/families'
  },
];
const footerTopImage = [
    {
        img: facebookImg,
        link: 'https://www.facebook.com/talkingbibles'
    },
    {
        img: instagramImg,
        link: 'https://www.instagram.com/talking_bibles'
    },
    //{
    //    img: twitterImg,
    //    link: 'https://twitter.com/Talking_Bibles'
    //},
]
const headerImage = {
  Thumbnail,
  Frame,
  Cart
};
const playerButtons = {
    play: playImg,
    pause: pauseImg
}
const langs = [
  {
      "tag": "tb-ahk",
      "label": "Akha"
  },
  {
      "tag": "tb-amh",
      "label": "Amharic"
  },
  {
      "tag": "tb-amu",
      "label": "AmuzgoGuerrero"
  },
  {
      "tag": "tb-anu",
      "label": "Anuak"
  },
  {
      "tag": "tb-arb-erv",
      "label": "Arabic(Easy-to-Read)"
  },
  {
      "tag": "tb-arb-vd",
      "label": "Arabic(VanDyke)"
  },
  {
      "tag": "tb-gax-arsi",
      "label": "ArsiOromo"
  },
  {
      "tag": "tb-bfg",
      "label": "Badaga"
  },
  {
      "tag": "tb-bfa",
      "label": "Bari"
  },
  {
      "tag": "tb-bem",
      "label": "Bemba(Zambia)"
  },
  {
      "tag": "tb-ben",
      "label": "Bengali"
  },
  {
      "tag": "tb-bgl",
      "label": "Bo(Laos)"
  },
  {
      "tag": "tb-gax-borana",
      "label": "Borana"
  },
  {
      "tag": "tb-ilk",
      "label": "Bugkalot(Ilongot)"
  },
  {
      "tag": "tb-yue",
      "label": "Cantonese"
  },
  {
      "tag": "tb-khm-ccpc",
      "label": "CentralKhmer"
  },
  {
      "tag": "tb-cly",
      "label": "ChantinoLachao-Yolotepec"
  },
  {
      "tag": "tb-cya",
      "label": "ChatinoNopala"
  },
  {
      "tag": "tb-nya",
      "label": "Chichewa"
  },
  {
      "tag": "tb-cle",
      "label": "ChinantecLealao"
  },
  {
      "tag": "tb-cpa",
      "label": "ChinantecPalantla"
  },
  {
      "tag": "tb-ces",
      "label": "Czech"
  },
  {
      "tag": "tb-dks",
      "label": "DinkaBor"
  },
  {
      "tag": "tb-nld",
      "label": "Dutch"
  },
  {
      "tag": "tb-eng-esv",
      "label": "English(ESV)"
  },
  {
      "tag": "tb-eng-kjv",
      "label": "English(KJV)"
  },
  {
      "tag": "tb-ewe",
      "label": "Ewe"
  },
  {
      "tag": "tb-cdo",
      "label": "FuzhouChinese"
  },
  {
      "tag": "tb-lug",
      "label": "Ganda"
  },
  {
      "tag": "tb-gno",
      "label": "Gondi"
  },
  {
      "tag": "tb-hat",
      "label": "HaitianCreole"
  },
  {
      "tag": "tb-hau",
      "label": "Hausa"
  },
  {
      "tag": "tb-heb",
      "label": "Hebrew(Letteris)"
  },
  {
      "tag": "tb-hnj",
      "label": "Hmong(BlueMeo)"
  },
  {
      "tag": "tb-nan-hok",
      "label": "Hokkien"
  },
  {
      "tag": "tb-hch",
      "label": "Huichol"
  },
  {
      "tag": "tb-ita",
      "label": "Italian(RivedutaLuzziRevised)"
  },
  {
      "tag": "tb-ium",
      "label": "IuMien"
  },
  {
      "tag": "tb-jav",
      "label": "Javanese"
  },
  {
      "tag": "tb-kin",
      "label": "Kinyarwanda"
  },
  {
      "tag": "tb-swh",
      "label": "Kiswahili"
  },
  {
      "tag": "tb-ktu",
      "label": "Kituba"
  },
  {
      "tag": "tb-kri",
      "label": "Krio"
  },
  {
      "tag": "tb-lhm",
      "label": "Lhomi"
  },
  {
      "tag": "tb-lin",
      "label": "Lingala"
  },
  {
      "tag": "tb-mas",
      "label": "Maasai"
  },
  {
      "tag": "tb-mkd",
      "label": "Macedonian"
  },
  {
      "tag": "tb-cmn",
      "label": "MandarinChinese"
  },
  {
      "tag": "tb-mar",
      "label": "Marathi"
  },
  {
      "tag": "tb-mke",
      "label": "Mawchi"
  },
  {
      "tag": "tb-vmy",
      "label": "MazatecAyautla"
  },
  {
      "tag": "tb-maq",
      "label": "MazatecChiquihuitlán"
  },
  {
      "tag": "tb-maj",
      "label": "MazatecJalapadeDíaz"
  },
  {
      "tag": "tb-mco",
      "label": "MixeCoatlán"
  },
  {
      "tag": "tb-mir",
      "label": "MixeIsthmusGuichicovi"
  },
  {
      "tag": "tb-mxq",
      "label": "MixeJuquila"
  },
  {
      "tag": "tb-neq",
      "label": "MixeNorthCentral"
  },
  {
      "tag": "tb-mih",
      "label": "MixtecChayuco"
  },
  {
      "tag": "tb-miz",
      "label": "MixtecCoatzospan"
  },
  {
      "tag": "tb-mxt",
      "label": "MixtecJamiltepec"
  },
  {
      "tag": "tb-mie",
      "label": "MixtecOcotepec"
  },
  {
      "tag": "tb-mil",
      "label": "MixtecPeñoles"
  },
  {
      "tag": "tb-mio",
      "label": "MixtecPinotepaNacional"
  },
  {
      "tag": "tb-mxb",
      "label": "MixtecTezoatlán"
  },
  {
      "tag": "tb-mur",
      "label": "Murle"
  },
  {
      "tag": "tb-ngu",
      "label": "NahuatlGuerrero"
  },
  {
      "tag": "tb-ncl",
      "label": "NahuatlMichoacán"
  },
  {
      "tag": "tb-nhy",
      "label": "NahuatlNorthernOaxaca"
  },
  {
      "tag": "tb-npl",
      "label": "NahuatlSoutheasternPuebla"
  },
  {
      "tag": "tb-nav",
      "label": "Navajo"
  },
  {
      "tag": "tb-npi",
      "label": "Nepali(India)"
  },
  {
      "tag": "tb-kxm",
      "label": "NorthernKhmer(OnlyNewTestament)"
  },
  {
      "tag": "tb-nus",
      "label": "Nuer"
  },
  {
      "tag": "tb-ory",
      "label": "Oriya"
  },
  {
      "tag": "tb-otq",
      "label": "OtomiQuerétaro"
  },
  {
      "tag": "tb-pce",
      "label": "Palaung"
  },
  {
      "tag": "tb-pes",
      "label": "Persian(1976Sharief)"
  },
  {
      "tag": "tb-pol",
      "label": "Polish"
  },
  {
      "tag": "tb-poi",
      "label": "PopolucaHighland"
  },
  {
      "tag": "tb-pan",
      "label": "Punjabi"
  },
  {
      "tag": "tb-rus",
      "label": "Russian"
  },
  {
      "tag": "tb-shp",
      "label": "Shipibo-Conibo"
  },
  {
      "tag": "tb-slk",
      "label": "Slovak"
  },
  {
      "tag": "tb-som",
      "label": "Somali"
  },
  {
      "tag": "tb-spa-lbi",
      "label": "Spanish(LaBibliaIllustrada)"
  },
  {
      "tag": "tb-sun",
      "label": "Sunda"
  },
  {
      "tag": "tb-tgl",
      "label": "Tagalog"
  },
  {
      "tag": "tb-tel",
      "label": "Telugu"
  },
  {
      "tag": "tb-nan-teo",
      "label": "TeochewChinese"
  },
  {
      "tag": "tb-tee",
      "label": "TepehuaHuehuetla"
  },
  {
      "tag": "tb-tir",
      "label": "Tigrigna"
  },
  {
      "tag": "tb-tog",
      "label": "Tonga"
  },
  {
      "tag": "tb-tzj",
      "label": "Tzutujil"
  },
  {
      "tag": "tb-urd",
      "label": "Urdu"
  },
  {
      "tag": "tb-vie",
      "label": "Vietnamese(1926Khoi-Cadman)"
  },
  {
      "tag": "tb-xan",
      "label": "Xamtanga"
  },
  {
      "tag": "tb-tsc",
      "label": "Xitshwa(Tswa)"
  },
  {
      "tag": "tb-guu",
      "label": "Yanomamö"
  },
  {
      "tag": "tb-ztq",
      "label": "ZapotecQuioquitani-Quierí"
  },
  {
      "tag": "tb-zab",
      "label": "ZapotecSanJuanGuelavía"
  },
  {
      "tag": "tb-zty",
      "label": "ZapotecYatee"
  }
];

export { 
  navData,
  InformationData,
  aboutUs,
  footerImage,
  footerTopImage,
  headerImage,
  sliderData,
  hexagoneData,
  TextimonialsData,
  DonationData,
  StoreData,
  ListenBibleData,
  TalkingBibleData,
  playerButtons,
  langs
};
