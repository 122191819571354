import React from 'react'
import { Box, Flex, Text } from '@chakra-ui/react';
import './style.scss';

const DonateButton = ({onClick}) => {
  return (
    <Box
      pos={'fixed'}
      bottom={'10%'}
      zIndex={2}
      id='donate-btn'
    >
      <Flex 
        className='donate-btn'
        filter={'drop-shadow(rgba(0, 0, 0, 0.75) 7px 4px 5px)'}
        onClick={() => onClick()}
      >
        <Box className='donate-img'></Box>
        <Text 
          className='donate-text'
        > Donate </Text>
      </Flex>
    </Box>
  )
}

export default DonateButton