import React, { useEffect, useState } from 'react'
import { Box, Image, Text } from "@chakra-ui/react";
import { documentToHtmlString,  } from '@contentful/rich-text-html-renderer';
import { contentful } from "../Config/contentful";
import { formatOctagons, formatPrivacy, formatRichTextStyle } from "../utils/formatElements";
import Header from '../Components/Header/index';
import Hexagone from '../Components/Common/Hexagone';
import Footer from '../Components/Footer/index';

const PrivacyPolicy = ({isFaq}) => {
  const [headerImage, setHeaderImage] = useState(null);
  const [privacyData, setPrivacyData] = useState(null);
  const [hexagonData, setHexagonData] = useState(null);

  const getContentfulData = async () => {
    const response = await contentful.getEntries("");
    if(!response.items) return;
    const octagonCollection = [];
    const privacyCollection = [];
    await response.items.forEach((item) => {
      const {sys: {contentType: {sys: {id}}}} = item;

      // Get Collection of elements
      switch (id.toLowerCase()) {
        case "octagondetail":
          octagonCollection.push(item);
          break;
        case "privacypolicy":
          privacyCollection.push(item);          
          break;
        default:
          break;
      }
    });    
    const data = formatPrivacy(privacyCollection)
      .filter(item => item.name === (isFaq ? 'FAQ' : 'Privacy Policy'));
    setHexagonData(formatOctagons(octagonCollection));
    if (data.length > 0) {
      setPrivacyData(formatRichTextStyle(documentToHtmlString(data[0].content)));
      setHeaderImage(data[0].headerImage);
    }
  }

  useEffect( () => {
    getContentfulData();
  }, []);

  return (
    <>
      <Header />
      <Image 
        w={'100%'}
        h={'50vh'}
        maxH={'700px'}
        src={headerImage ?? ''}
        objectFit={'cover'}
        alt="Header"
      />
      <Box id={'content'} pb={'300px'} display={'flex'} justifyContent={'center'}>
        <Text
          marginTop={"30px"}
          fontSize={{ base: "15px", md: "20px", lg: "30px" }}
          lineHeight={{ base: "18px", md: "23px", lg: "33px" }}
          color={"black"}
          fontWeight={"medium"}
          fontFamily={'"Roboto"'}
          mb={'25px'}
          px={'150px'}
          maxW={'1130px'}
          dangerouslySetInnerHTML={{ __html: privacyData }}
        ></Text>
      </Box>
      <Hexagone elements={hexagonData} pb={"200px"} dropShadow={'drop-shadow(rgba(0, 0, 0, 0.9) 0px 0px 15px)'} />
      <Footer />
    </>
  )
}

export default PrivacyPolicy