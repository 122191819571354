import { Box, Button, CloseButton, Flex, HStack, Image, Input, Text, useNumberInput } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeLineItems, updateLineItems } from '../../../store/shopify';
import { selectCheckoutData } from '../../../store/shopify/selectors';

const LineItem = ({
  data
}) => {
  const dispatch = useDispatch()
  const checkoutData = useSelector(selectCheckoutData);
  const [price, setPrice] = useState(parseFloat(data.variant.price) * data.quantity);
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } = useNumberInput({
    step: 1,
    defaultValue: parseInt(data.quantity),
    min: 1,
    max: 100,
    precision: 0,
    onChange: async (valueAsString, valueAsNumber) => {
      const lineItemsToUpdate = [
        {
          id: data.id,
          quantity: valueAsNumber
        }
      ];      
      await dispatch(updateLineItems({checkoutId: checkoutData.id, lineItemsToUpdate}));
      setPrice(prev => parseFloat(data.variant.price) * valueAsNumber);
    }
  });

  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps({ isReadOnly: true });

  const removeItem = () => {
    dispatch(removeLineItems({
      checkoutId: checkoutData.id, 
      lineItemsToRemove: [data.id]
    }));
  };
  
  return <>
    <Box border={'1px solid #80808066'} borderRadius={'6px'} p={'5px'} mb={'8px'}>
      <Flex columnGap={'20px'} justifyContent={'space-between'}>
        <Image src={data?.variant.image.src} w={'70px'} h={'64px'} objectFit={'cover'} alt="Product"/>
        <Box w={'100%'}>
          <Text fontFamily={'Roboto'}>{data?.title}</Text>
          <Text fontFamily={'Roboto'} fontSize={'13px'} textAlign={'center'} >{data?.variant.title}</Text>
          <Flex justifyContent={'space-between'} alignItems={'center'} w={'100%'} mt={'3px'}>
            <HStack maxW='125px' fontFamily={'Roboto'}>
              <Button {...dec} size='xs'>-</Button>
              <Input {...input} size='xs' px={'3px'} borderRadius={'6px'} textAlign={'center'}/>
              <Button {...inc} size='xs'>+</Button>
            </HStack>
            <Text fontFamily={'Roboto'} fontWeight={'bold'}>$ {price.toFixed(2)}</Text>
          </Flex>
        </Box>
        <Flex alignSelf={'start'}>
          <CloseButton size='sm' onClick={() => removeItem()}/>
        </Flex>
      </Flex>  
    </Box>
  </>;
};

export default LineItem;
