import React  from "react";
import {
  Box,
  Center,
  Text,
  Flex,
} from "@chakra-ui/react";
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import hexaBackgroundImg from "../../../assests/newHexa.png";
import './style.scss';

export default function Hexagone({elements, pb, dropShadow}) {

  return (
    <Box
      backgroundColor={"rgb(0,128,199)"}
      width={{ base: '100%', sm: '100%', md: '100%', lg: '100%' }}
      paddingBottom={ pb ?? "80px"}
      boxShadow={"rgb(0 0 0 / 75%) 0px -10px 25px"}
    >
      <Flex
        className="hexagone"
        justifyContent={"space-evenly"}
      >
        {elements?.map((item, index) => (
          <Box 
            key={`hexagone-${index}`}
            display='flex' 
            flexFlow='column' 
            alignItems='center' 
            width={"fit-content"}
            className="hexagone-parentItem"
          >
            <Center
              className="hexagone-item"
              justifyContent={"center"}
              alignItems={"center"}
              style={{
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundImage: `url(${hexaBackgroundImg})`,                
                filter: dropShadow,
                zIndex: '1'
              }}
            >
              <Center
                className="hexagone-subItem"
                flexDirection={"column"}
                style={{
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundImage: `url(${item.img})`,
                }}
              >
                <Flex
                  direction={"column"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  fontFamily={"Roboto"}
                >
                  <Text
                    className="hexagone-amount"
                    textAlign={"center"}
                    fontWeight={'extrabold'}
                    color={"white"}
                    fontFamily={"Roboto"}
                    style={{
                      marginTop: 25,
                    }}
                  >
                    {item.amount}+
                  </Text>
                  <Text
                    className="hexagone-title"
                    textAlign={"center"}
                    fontWeight={'bold'}
                    marginTop={'15px'}
                    color={"white"}
                    fontFamily={"Roboto"}
                  >
                    {item.title}
                  </Text>
                  <Flex
                    mt={'10px'}
                  >
                  </Flex>
                </Flex>
              </Center>
            </Center>

            <Box className="hexagone-intro-section" mt={5}>
              <Box
                className="hexagone-intro"
                textAlign={"center"}
                color={"white"}
                fontFamily={"Roboto"}                
              >
                <div dangerouslySetInnerHTML={{ __html: documentToHtmlString(item.intro) }}></div>
              </Box>
            </Box>
          </Box>
        ))}
      </Flex>
    </Box>
  );
}
