import React from "react";
import {
  Image,
  useMediaQuery,
  Center,
  Box,
  Link,
} from "@chakra-ui/react";
import './style.scss';
const Invitation = ({ image, alt, icon, link, name }) => {
  const [isMobile] = useMediaQuery("(max-width: 768px)");

  return (
    <Box
      style={{
        flexDirection: "column",
        boxShadow: "rgb(0 0 0 / 75%) 0px -10px 25px",
        position: "relative"
      }}
    >
      <Center>
        <Link isExternal href={link} _focus={{boxShadow: 'unset'}} w='100%'>
          <Image
            className="invitation"
            src={image}
            alt={alt}
            w='100%'
          />
        </Link>
        <Image 
          w={!isMobile ? (name === 'India' ? '109px' : '130px') : (name === 'India' ? '63px' : '75px')} 
          marginTop={name === 'India' ? -42 : -75} 
          src={icon} position={"absolute"} 
          top={ !isMobile ? (name === 'India' ? "-25px" : "0px") : (name === 'India' ? "5px" : "29px")}
          alt={alt}
        />
      </Center>
    </Box>
  );
};

export default Invitation;
