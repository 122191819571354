import React from "react";
import v12 from "../../assests/yellow-silver.png";
import listen from "../../assests/t2Image.png";
import Iframe from "react-iframe";

import {
  Flex,
  Heading,
  Text,
  Center,
  Image,
  useMediaQuery,
  Box,
} from "@chakra-ui/react";

const TalkingAboutBible = ({elements}) => {
  const [isMobile] = useMediaQuery("(max-width: 768px)");
 
  return (
    <Box
      px={isMobile ? 5 : '70px'}
      id={"blog"}
      bgColor={"rgb(222,216,225)"}
      position={'relative'}
      boxShadow={"rgb(0 0 0 / 75%) 0px -10px 25px"}
    >
      <Center>
        <Image w={!isMobile ?'130px':''} marginTop={isMobile ? '-40px' : -75} src={isMobile ? listen : v12} alt="WHY A TALKING BIBLE?"/>
      </Center>
      <Flex py={24} justifyContent={'space-between'} flexWrap={'wrap'} rowGap={'40px'} columnGap={'40px'}>
        <Flex flexDirection={"column"} flex={{sm: 1, md: 1, lg: 0.3}} position={"relative"} minW={'280px'}>
          <Flex
            position={"absolute"}
            top={"-70px"}
            style={{
              marginTop: isMobile ? 3 : 10,
              marginBottom: isMobile ? 3 : 10,
            }}
          >
          </Flex>
          <Heading
            fontStyle={'normal'}
            fontSize={"32px"}
            lineHeight={"33px"}
            fontFamily={'Roboto'}
            fontWeight={'bold'}
            dangerouslySetInnerHTML={{ __html: elements?.title }}
          />
          <Text
            style={{
              fontFamily: "Roboto",
            }}
            fontSize={"24px"}
            marginTop={10}
            dangerouslySetInnerHTML={{ __html: elements?.text }}
          />
        </Flex>
        <Flex
          flex={{sm: 1, md: 1, lg: 0.7}}
          maxWidth={{sm: '100%', md: '100%', lg: '50vw', xl: '50vw'}}
          height={{sm: 'calc(90vw * 0.568)', md: 'calc(60vw * 0.568)', lg:'calc(50vw * 0.568)', xl:'calc(50vw * 0.568)'}}
          maxH={{sm: '272px', md: '272px', lg: 'unset'}}
          filter={'drop-shadow(rgba(0, 0, 0, 0.75) 15px 15px 25px)'}
          minW={'280px'}
          paddingRight={{lg: '53px'}}
        >
          <Iframe
            url={elements?.video}
            width="100%"
            height="100%"
            display="initial"
            position="relative"
            allowFullScreen
            ariaLabel="talking bible"
            ariaLabelledby="Master"
          />
        </Flex>
      </Flex>
    </Box>
  );
};

export default TalkingAboutBible;
