import { createSlice } from "@reduxjs/toolkit";
import { setIndexOfInfo } from "./actions";


const PREFIX = 'static';
const initialState = {
  indexOfInfo: -1,
};

export const staticSlice = createSlice({
  name: PREFIX,
  initialState,
  reducers: {},  
  extraReducers: (builder) => {
    builder
      .addCase(
        setIndexOfInfo,
        (state, action) => {
          state.indexOfInfo = action.payload;
        }
      )
  }
})

export { setIndexOfInfo };
export default staticSlice.reducer;