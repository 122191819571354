import React from "react";
import {
  Box,
  SimpleGrid,
  Flex,
  Center,
  Text,
  Heading,
  Image,
  useMediaQuery,
} from "@chakra-ui/react";

import aboutImage from "../../assests/silver-purple.png";
import mobabou from "../../assests/mobabou.png";
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { useNavigate  } from 'react-router-dom';
import { formatStringForCapital } from "../../utils/formatPath";
const About = ({ elements }) => {
  const navigate  = useNavigate ();
  const [isMobile] = useMediaQuery("(max-width: 768px)");

  const readMore = () => {
    return `
    <span style="
      font-size: 20px;
      font-weight: bold;
      cursor: pointer;
      font-family: 'Roboto';
    ">
      Read +
    </span>
    `
  }
  
  return (
    <Flex
      id={"about-us"}
      width={{ base: '100%', sm: '100%', md: '100%' ,lg:'100%'}}
      backgroundColor={!isMobile ? "#F1DFEB" : "rgb(213,209,218)"}
      flexDirection={"column"}
      position={'relative'}
      boxShadow={'rgb(0 0 0 / 75%) 0px -5px 25px'}
    >
      <Center marginTop={isMobile ? -50 : -75} zIndex={"1"}>
        <Image w={!isMobile ?'130px':''} src={isMobile ? mobabou : aboutImage} alt="About Us" />
      </Center>
      <SimpleGrid
        paddingY={isMobile ? 5 : 10}
        paddingX={isMobile ? 5 : '70px'}
        columns={3}
        spacing={3}
        marginBottom={10}
        alignItems={isMobile ? "flex-start" : ""}
        justifyContent={"space-between"}
        flexDirection={isMobile ? "column" : "row"}
        minChildWidth={'270px'}
      >
        {elements?.map((item, index) => {
          console.warn('elemnt =>', item)
          return (
            <Flex
              key={`about-${index}`}
              justifyContent={'center'}
              marginBottom={'50px'}
            >
              <Box
                alignItems={"left"}
                cursor={'pointer'}
                onClick={()=> navigate ('/about-us' + formatStringForCapital(item.name))}
                maxW={'400px'}
              >
                <Flex
                  justifyContent={"flex-end"}
                  flexDirection={"column"}
                  w={'100%'}
                  height={"347px"}
                  style={{
                    backgroundPosition: "center",
                    backgroundImage: `url(${item.img})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <Flex
                    style={{
                      height: "20px",
                      marginBottom: index === 2 ? 22 : 20,
                      marginLeft: index === 2 ? 12 : 10,
                    }}
                  >
                  </Flex>
                </Flex>

                <Heading
                  paddingTop={7}
                  fontWeight={"bold"}
                  fontFamily={"arial"}
                  fontSize={isMobile ? "15px" : "30px"}
                  color={"#000000"}
                  style={{
                    fontFamily: 'Roboto',
                    fontWeight:'bold'
                  }}
                  dangerouslySetInnerHTML={{ __html: documentToHtmlString(item.title)}}
                >
                </Heading>
                
                  <Text
                    fontWeight={"300"}
                    color={"black"}
                    marginTop={2}
                    w={'100%'}
                    style={{
                      fontFamily: 'Roboto'
                    }}
                    dangerouslySetInnerHTML={{
                      __html: documentToHtmlString(item.intro).slice(0, documentToHtmlString(item.intro).length -4) + readMore() + '</p>'
                    }}
                  >    
                  </Text>
              </Box>
            </Flex>
          );
        })}
      </SimpleGrid>
    </Flex>
  );
};

export default About;
