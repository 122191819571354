import React, { useEffect, useState } from "react";
import {
  Flex,
  Text,
  Spacer,
  Image,
  Center,
  Box,
  Button,
  List
} from "@chakra-ui/react";
import { ChevronUpIcon } from "@chakra-ui/icons";
import { useNavigate, useLocation } from 'react-router-dom';
import { motion, useAnimation } from "framer-motion";
import { Link, scroller, animateScroll as scroll } from "react-scroll";

import { useDispatch, useSelector } from "react-redux";
import { selectCheckoutData } from "../../store/shopify/selectors";
import { toggleCart } from "../../store/shopify";
import { getIndexOfInfo } from "../../store/static/selectors";
import { setIndexOfInfo } from "../../store/static";
import Cart from '../Information/Store/Cart';
import DonateButton from "../Common/DonateButton";
import { navData, headerImage } from "../../Constants/data";

import style from "./style";
import "./header.scss";
import { formatPath } from "../../utils/formatPath";

const Header = () => {
  const menuVariants = {
    'open': {
      transition: {
        staggerChildren: 0.3,
        delayChildren: 0.4,
      },
    },
    closed: {
      transition: {
        staggerChildren: 0.9,
        staggerDirection: -1,
      },
    },
  };
  const [isMenu, setIsMenu] = useState(true);
  const [open, setOpen] = useState(false);
  const [isPositionTop, setIsPositionTop] = useState(true);
  const controls = useAnimation();
  const checkoutData = useSelector(selectCheckoutData);
  const indexOfSubInfo = useSelector(getIndexOfInfo)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const handleScroll = () => {
    if(window.scrollY === 0 && isPositionTop === false)
      setIsPositionTop(true);
    else if (window.scrollY > 0 && isPositionTop === true)
      setIsPositionTop(false);
  }
  window.addEventListener("scroll", handleScroll, { passive: true });

  const _toggleMenu = () => {
    if (open) {
      setOpen(false);
      setIsMenu(!isMenu);
      controls.start((i) => ({
        opacity: 1,
        x: -100,
        transition: {
          delay: i * 0.3,
        },
      }));
    } else {
      setOpen(true);
      setIsMenu(!isMenu);
    }
  };

  const MenuClassName = () => {
    if (isMenu) {
      return isPositionTop ? "menu is-active" : "menu is-active-scrolling";
    } else {
      return isPositionTop ? "menu is-non-active" : "menu is-non-active-scrolling";
    }
  };

  const CartButton = () => {
    let quantity = 0;
    checkoutData?.lineItems.forEach(item => {
      quantity += item.quantity;
    });

    return quantity > 0 ? <>
      <Center>
        <Button variant={'ghost'} fontSize={'15px'} fontWeight={'normal'} transition={'all 0.3s'} onClick={()=>dispatch(toggleCart())}>
          <Flex alignItems={'center'} columnGap={'10px'}>
            <Image src={headerImage.Cart} h={'24px'} alt="Add to Cart"/>
              <span className="Cart-Text">Cart</span> <span>({quantity}<span className="Cart-Text"> items</span>)</span>
          </Flex>
        </Button>
      </Center>
    </> : null;
  }

  const gotoHome = (id, title) => {
    const sub_infos = ['Donate', 'Store', 'Newsletter'];
    const indexOfInfo = sub_infos.findIndex(e => e === title);
    if (location.pathname === '/' && indexOfSubInfo >= 0 && indexOfInfo >= 0) {
      dispatch(setIndexOfInfo(indexOfInfo));
      scroller.scrollTo(id, { smooth: true });
      return;
    }   
    if (location.pathname !== '/' ) {
      navigate('/');
      if (indexOfInfo === -1) {
        setTimeout(() => {
          scroller.scrollTo(id, { smooth: true });
        }, 1000);
      }
    } else {
      if (indexOfInfo === -1) {
        scroller.scrollTo(id, { smooth: true });
      } else {
        scroller.scrollTo(id, { smooth: true });
        setTimeout(() => {
          dispatch(setIndexOfInfo(indexOfInfo));
        }, 1200);
      }
    } 
  }

  useEffect(() => {
    // the valid section IDs are about-us, blog, listen, testimonials, information, donate, store, ;
    const hashEls = location.hash?.replace('#', '').toLocaleLowerCase().split('/');
    const { path } = formatPath(location);
    document.addEventListener('readystatechange', () => {
      if ( document.readyState === 'complete') {
        if (path === 'testimonies') {
          setTimeout(() => {
            scroller.scrollTo('testimonials', { smooth: true });
          }, 1000);
          return;
        }
        if (hashEls[0].length > 0) {
          let elId = '';
          switch (hashEls[0]) {
            case 'donate':
            case 'store':
            case 'newsletter':
              elId = 'information';
              break;
            default:
              elId = hashEls[0];
              break;
          }
          scroller.scrollTo(elId, { smooth: true });
        }
      }
    })
    return () => {
      document.removeEventListener('readystatechange', () => {});
    }
  }, [])
  

  return (
    <>
      <Cart />
      <Flex
        flexDirection={"column"}
        fontFamily={"Roboto"}
        color={"#595959"}
        fontWeight={"bold"}
        position={"fixed"}
        zIndex={"10"}
        width={'100%'}
      >
        <Flex
          {...style.nav}
          width={{
            base: "100%",
            sm: "100%",
            md: "100%",
            lg: "100%",
          }}
          boxShadow={isPositionTop ? 'inset 0px -8px 23px rgb(0 0 0 / 56%)' : ''}
          transition={'box-shadow 0.5s ease-in-out, padding 0.5s'}
          className={isPositionTop ? "header-bar-isTop" : "header-bar"}
        >
          <Flex
            onClick={() => {
              _toggleMenu();
            }}
            cursor={"pointer"}
            flexDirection={"row"}
            zIndex={1}
          >
            <Flex alignItems={"center"} className={'header-menu-frame'}>
              <Image
                objectFit={"contain"}
                src={headerImage.Frame}
                transition={'width 0.5s'}
                alt="Header root item"
              />
              <Text
                className={"li"}
                style={{
                  padding: 12,
                  marginRight: 8,
                }}
                zIndex={"3"}
                fontFamily={"Roboto"}
                transition={'font 0.5s'}
              >
                MENU
              </Text>
            </Flex>
          </Flex>
          <Flex
            variants={menuVariants}
            className={MenuClassName()}
            zIndex={"0"}
            position={'absolute'}
          >
            {navData.map((item, index) => {
              return (
                <Flex
                  key={`navdata-${index}`}
                  flexDirection={"column"}
                  direction={"row"}
                  style={
                    {
                      transform: "translateX(-100rem)",
                      animation: "slideIn .5s forwards"
                    }
                  }
                >
                    <List cursor={"pointer"} display='flex' alignItems={"center"} className={ open ? 'header-menu' : 'header-menu-closed'} transition={'height 0.5s'}>
                      <motion.li
                        animate={{
                          y: -100 * index,
                        }}
                        transition={{
                          type: "spring",
                          bounce: 0.25,
                          duration: 0.5,
                          delay: 5 * index,
                        }}
                      >
                        <Image
                          objectFit={"contain"}
                          src={item.icon}
                          transition={'all 0.5s'}
                          className={item.title}
                          onClick={() => gotoHome(item.id, item.title)} 
                          alt="Go to Home"
                        />
                      </motion.li>
                      <motion.li
                        custom={index}
                        animate={{
                          y: -100 * index,
                        }}
                        transition={{
                          type: "spring",
                          bounce: 0.25,
                          duration: 0.5,
                        }}
                      >
                        <Text
                          className={item.title}
                          animate={{
                            y: -100 * index,
                          }}
                          transition={'all 0.5s'}
                          fontFamily={"Roboto"}
                          onClick={() => gotoHome(item.id, item.title)} 
                        >
                          {item.title}
                        </Text>
                      </motion.li>
                    </List>
                </Flex>
              );
            })}
          </Flex>
          <Spacer> </Spacer>
            <CartButton />
            <Center marginLeft={10} cursor={'pointer'} className={'header-logo'}>
              <Image
                src={headerImage.Thumbnail}
                marginRight={'10px'}
                objectFit={"contain"}
                transition={'width 0.5s'}
                onClick={() => gotoHome('root', 'home')}
                alt="Go to Home"
              />
            </Center>
        </Flex>

      </Flex>
      <DonateButton onClick={() => gotoHome('sub-information','Donate')} />
      {!isPositionTop && <Link smooth={true} to={'carousel-section'}>
        <Box pos={'fixed'} 
          bottom={'20px'} 
          right={'60px'} 
          zIndex={2}
        >
          <ChevronUpIcon 
            color={'#fff'} 
            w={"12"} 
            h={"12"}
            padding={'8px'}
            borderRadius={'50%'} 
            backgroundColor={'rgba(0, 0, 0, 0.7)'} 
            cursor={'pointer'}
            _hover={{
              paddingBottom: '13px',
              paddingTop: '3px',
              backgroundColor: 'black'
            }}
            transition={'all 0.3s'}
          />
        </Box>
      </Link>}
    </>
  );
};

export default Header;
