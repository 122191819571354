import React, { useState } from "react";
import "./style.scss";
import {
  Box,
  Text,
  Heading,
  useMediaQuery,
  HStack,
} from "@chakra-ui/react";
import Carousel from "react-bootstrap/Carousel";

const Stats = ({ img, elements }) => {
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <>
      {elements.length > 1 && (
        <Box
          id="carousel-section"
          width={{ base: "100%", sm: "100%", md: "100%", lg: "100%" }}
          style={{
            backgroundPosition: "center",
            backgroundSize: "cover",

            backgroundRepeat: "no-repeat",
            backgroundImage: isMobile ? ` url(${img})` : null,
          }}
          className={!isMobile ? "root" : "root"}
        >
          <HStack justify={"center"} pos={"relative"}>
            <img
              className={"image active"}
              src={img}
              alt=""
              style={{
                zIndex: `-2`,
              }}
            />
            <Text
              className="carousel-audio-text"
              fontSize={{
                base: "14px",
                md: "14px",
                lg: "16px",
                xl: "16px",
                "2xl": "18px",
              }}
              fontWeight={"bold"}
              fontFamily={"Roboto"}
              noOfLines={2}
              textAlign={"center"}
              pos={"absolute"}
              top={"330px"}
              mx={"8px"}
            >
              {elements[index].subText}
            </Text>
          </HStack>
          <Carousel onSelect={handleSelect}>
            {elements.map((item, i) => (
              <Carousel.Item key={`slider-${i}`}>
                <div className="carousel-header"></div>
                <Carousel.Caption
                  style={{
                    bottom: "unset",
                    top: "119px",
                    right: "40px",
                    left: "40px",
                  }}
                >
                  <Heading
                    as='h1'
                    textAlign={"center"}
                    py={10}
                    fontSize={{
                      base: "18px",
                      md: "24px",
                      lg: "25px",
                      xl: "55px",
                      "2xl": "60px",
                    }}
                    style={{
                      fontFamily: "Roboto",
                      textShadow:
                        i === 1 ? "rgb(255 255 255) 0px 0px 17px" : "",
                      color: i === 1 ? "rgb(255 255 255 / 13%)" : "",
                    }}
                  >
                    {item.mainText}
                  </Heading>
                </Carousel.Caption>
              </Carousel.Item>
            ))}
          </Carousel>
        </Box>
      )}
    </>
  );
};

export default Stats;
