import React, { useState } from 'react'
import { Box, Flex, Image } from '@chakra-ui/react';
import ReactPlayer from 'react-player';
import playImg from '../../../../assests/play.png'
import './style.scss';
var youtubeThumbnail = require('youtube-thumbnail');

const VideoPlayer = ({videoSrc, onClick}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const getImgSrc = () => {
    const urls = youtubeThumbnail(videoSrc);
    return urls ? urls.default.url.replace('http:', 'https:') : '';
  }

  return (
    <Box 
      onClick={() => onClick()} 
      pos={'relative'} 
      cursor={'pointer'}
      onMouseEnter={() => setIsPlaying(true)}
      onMouseLeave={() => {
        setIsPlaying(false)
      }}
      className={'video-carousel-item'}
    >
      <Image 
        src={getImgSrc()} 
        width={'100%'} 
        zIndex={'-1'} 
        className={'carousel-preview-img'}
        alt="Video Preview"
      />
      {
        isPlaying &&
          <ReactPlayer
            className={'carousel-video'}
            playing={isPlaying} 
            style={{zIndex: 0, position: 'absolute', top: '0px'}}
            width='100%'
            url={videoSrc}
            volume={0}
            pip
          />          
      }
      <Box pos={'absolute'} w={'100%'} h={'100%'} top={'0px'}>
        <Flex justifyContent={'center'} alignItems={'center'} h={'100%'}>
          <Image src={playImg} opacity={isPlaying ? 0 : 1} alt="Video player"/>
        </Flex>          
      </Box>
    </Box>
  )
}

export default VideoPlayer