import React, { useEffect, useState } from "react";
import { Flex, Image, Text, Select, Wrap, WrapItem, Box } from "@chakra-ui/react";
import { useSelector, useDispatch } from 'react-redux';
import { StoreData } from "../../../Constants/data";
import cart from "../../../assests/cart.png";
import { langs } from '../../../Constants/data';
import Carousel from 'react-multi-carousel';
import { selectCheckoutData } from "../../../store/shopify/selectors";
import { addLineItems, toggleCart } from "../../../store/shopify";
import './style.scss';

const Store = ({
  products
}) => {
  const dispatch = useDispatch();
  const checkoutData = useSelector(selectCheckoutData);
  const [imgIndex, setImgIndex] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productVariant, setProductVariant] = useState(null);
  const [selectedLang, setSelectedLang] = useState(langs[0].label);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 10,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1299 },
      items: 6,
      partialVisibilityGutter: 30 
    },
    tablet: {
      breakpoint: { max: 1298, min: 896 },
      items: 5,
    },
    mdTablet: {
      breakpoint: { max: 895, min: 465 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
    }
  };

  const selectLanguage = (lang) => {
    const items = products.filter(item => item.handle === lang);
    if (items?.length > 0) {
      setProductVariant(items[0].variants[0]);
      setSelectedProduct(items[0]);
      setSelectedLang(langs.filter(item => item.tag === lang)[0].label);
    }
  }

  const selectVariant = (id) => {
    const variant = selectedProduct?.variants.filter(item => item.id === id);
    if (variant?.length > 0) {
      setProductVariant(variant[0]);
    }
  }
  
  const addToCart = async () => {
    const lineItemsToadd = [{variantId: productVariant.id, quantity: 1}];
    await dispatch(addLineItems({checkoutId: checkoutData.id, lineItemsToadd}));
    await dispatch(toggleCart());
  }

  const getImgs = () => {
    let imgs = [];
    products?.forEach(element => {
      element.images.forEach(img => {
        if (imgs.length > 20) return;
        imgs.push(img.src);
      });
    });
    return imgs;
  };

  useEffect(() => {
    if (products && products.length > 0) {
      setSelectedProduct(products[0]);
      if (products[0].variants && products[0].variants.length > 0) {
        setProductVariant(products[0].variants[0]);
      }
    }
  }, [products])
  
  return (
      <Wrap w={'100%'} margin={'0px'}>
        <WrapItem w={'100%'} margin={'0px'}>
          <Box w={'100%'}>
            <Flex w={'100%'} mb={'45px'} pos={'relative'} flexWrap={'wrap'}>
              <Flex
                flexDirection={"column"}
                backgroundColor={"white"}
                className={'product-option'}
              >
                <Text 
                  fontWeight={"bolder"} 
                  fontSize={30}
                  fontFamily={'Roboto'}
                >
                  {selectedLang}<br/>Talking Bible® <br/>{productVariant?.title}
                </Text>
                <Text 
                  my={5}
                  fontSize={'21px'}
                  fontFamily={'Roboto'}
                >
                  The {selectedProduct?.title} {StoreData.text}
                </Text>
                <Text fontFamily={'Roboto'} fontSize={30} fontWeight={'bold'} dangerouslySetInnerHTML={{__html: '$' + productVariant?.price}}></Text>
                <Select 
                  mt={'40px'} 
                  fontFamily={'Roboto'}
                  bg={'grey'}
                  onChange={$event => selectLanguage($event.target.value)}
                >
                  {langs && langs.map(item => (
                    <option key={item.tag} value={item.tag}>{item.label}</option>
                  ))}
                </Select>
                {selectedProduct && (
                  <Select 
                    mt={'40px'} 
                    fontFamily={'Roboto'}
                    bg={'grey'}
                    onChange={$event => selectVariant($event.target.value)}
                  >
                    {selectedProduct.variants?.map(item => (
                      <option key={item.id} value={item.id}>{item.title}</option>
                    ))}
                  </Select>
                )}
                <Flex mt={'40px'} alignItems={'center'} >
                  <Image cursor={'pointer'} src={cart} objectFit={"cover"} filter={'drop-shadow(rgba(0, 0, 0, 0.9) 3px 0px 4px)'} onClick={() => addToCart()} alt="Add to Cart"></Image>
                  <Text cursor={'pointer'} paddingLeft={5} fontSize={'20px'} fontWeight={'bold'} alignSelf={"center"} fontFamily={'Roboto'} onClick={() => addToCart()}>
                    ADD TO CART
                  </Text>
                </Flex>
              </Flex>
              <Flex
                className="product"
                pos={'absolute'}
                right={'0px'}
                style={{
                  paddingBottom: '40px',
                  alignItems: "center",
                  backgroundSize: "contain",
                  flexDirection: 'column'
                }}
              >
                <Image
                  className="product-img"
                  alt="Product"
                  src={getImgs()[imgIndex]}
                ></Image>

                <Box mt={'20px'} className="carousel">
                  <Carousel responsive={responsive}>
                      {getImgs().map((item, index) => (
                        <div 
                          key={'product-img-' + index}
                          style={{'cursor': "pointer"}}
                          onClick={() => setImgIndex(index)}
                          alt="Product"
                        >
                          <Image src={item}/>
                        </div>
                      ))}
                  </Carousel>
                </Box>
              </Flex>
            </Flex>            
            <Text
              className="product-description"
              dangerouslySetInnerHTML={{__html: StoreData.subText}}
              padding={'50px'}
              fontSize={'20px'}
              fontFamily={'Roboto'}
            ></Text>
          </Box>
        </WrapItem>
      </Wrap>
  );
};

export default Store;
