import React, { useEffect, useState } from "react";
import {
  HStack,
  Flex,
  Text,
  Image,
  SimpleGrid,
  Box,
  Center,
  useMediaQuery,
} from "@chakra-ui/react";
import { ListenBibleData } from "../../../Constants/data";
import { S3, S3_config } from "../../../Config/aws";
import { formatS3Contents } from "../../../utils/formatS3Content";
import Select from "./Select";
import AudioPlayer from "./AudioPlayer";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { formatRichTextStyle } from "../../../utils/formatElements";

const ListBible = ({elements}) => {
  const { L2Img, vectorImg, HeadPhoneImg, playGonImg, text } = ListenBibleData;
  const [isMobile] = useMediaQuery("(max-width: 768px)");

  const [totalData, setTotalData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [lang, setLang] = useState(null);
  const [book, setBook] = useState(null);
  const [chapter, setChapter] = useState(null);
  const [audio, setAudio] = useState(null);
  const [autoPlay, setAutoPlay] = useState(false);
  const [s3Pramas, updateS3Params] = useState({
    Bucket: S3_config.Bucket,
    Delimiter: "",
  });

  const getContents = () => {
    S3.listObjects(s3Pramas, (err, data) => {
      if (err) {
        console.error("Get Objects from AWS_S3 Error =>", err);
      } else {
        setHasNextPage(data.Contents.length === 1000);
        if (data.Contents.length > 0) {
          updateS3Params((prev) => ({
            ...prev,
            Marker: data.Contents[data.Contents.length - 1].Key,
          }));
          formatS3Contents(data.Contents).then((items) =>
            setTotalData((prev) => [...prev, ...items]),
          );
        }
      }
    });
  };

  const onSelectLang = (val) => {
    setSelectedData(totalData.filter(s => s.lang === val));
    setLang(val);
    setBook(null);
  }

  const sortStrArray = (val) => {
    return val.sort((a, b) => {
      let fa = a.toLowerCase(),
          fb = b.toLowerCase();
  
      if (fa < fb) {
          return -1;
      }
      if (fa > fb) {
          return 1;
      }
      return 0;
    });
  }

  const sortBookArray = (val) => {
    return val
        .filter(s => s.testament === 'Old Testament' || s.testament === 'New Testament' )
        .sort((a, b) => {
          let fa = a.testament.toLowerCase(),
              fb = b.testament.toLowerCase();
      
          if (fa < fb) {
              return 1;
          }
          if (fa > fb) {
              return -1;
          }
          return 0;
        })
        .map(s => s.book);
  }
  const onSelectChapter = (val) => {
    setChapter(val);
    if(autoPlay) setAutoPlay(false);
  }

  const gotoNextChapter = () => {
    const chapters = [
      ...new Set(
        selectedData
          .filter(s => s.book === book)
          .map(item => item.chapter)
      )
    ]
    
    const index = chapters
    .findIndex(s => s == chapter);
    if (index > -1 && index < chapters.length - 1) {
      setChapter(chapters[index + 1]);
      if (!autoPlay) setAutoPlay(true);
    } else {
      if (autoPlay) setAutoPlay(false);
    }
  }

  useEffect(() => {
    if (hasNextPage) getContents();
    if (totalData > 0 && selectedData < 1000) setSelectedData(totalData[0]);
  }, [totalData]);
  
  useEffect(() => {
    setChapter(null);
    setAudio(null);
    if(autoPlay) setAutoPlay(false);
  }, [book, lang])

  useEffect(() => {
    if (chapter) {
      const els = selectedData.filter(s => s.book === book && s.chapter === chapter);
      if (els.length > 0) {
        setAudio(els[0].url);
      }
    }
  }, [chapter])

  return (
    <Box
      id={"listen"}
      px={isMobile ? 5 : '70px'}
      paddingBlock={[8, 8, 0]}
      height={["auto", "auto", "auto"]}
      minH={["auto", "auto", "70vh"]}
      style={{
        flexDirection: "column",
        backgroundColor: "white ",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundImage: `url(${HeadPhoneImg})`,
        position: 'relative',
        boxShadow: 'rgb(0 0 0 / 75%) 0px -10px 25px'
      }}
    >
      <Center height={"100px"}>
        <Image
          src={isMobile ? L2Img : vectorImg}
          marginTop={[-188, -188, -100]}
          marginLeft={"4px"}
          zIndex={1}
          w={!isMobile ? "77px" : ""}
          alt="Listen Audio"
        />
      </Center>

      <SimpleGrid columns={[2, 2, 2]} spacing={!isMobile ? "70px" : 10} minChildWidth={'280px'} pb={'60px'}>
        <Box>
          <HStack>
            <Text
              fontWeight={"900"}
              fontSize={"30px"}
              lineHeight={'1.2'}
              width={'18ch'}
              color={"white"}
              mb={'25px'}
              style={{
                fontFamily: "Roboto",
              }}
            >
              LISTEN TO THE BIBLE FOR FREE
            </Text>
            <Flex>
              <Flex
                style={{
                  marginTop: isMobile ? 10 : 30,
                  marginLeft: 10,
                }}
              ></Flex>
            </Flex>
          </HStack>
          <Flex wrap={'wrap'} maxW={'490px'} columnGap={10} rowGap={10} >
              <Select
                title={lang ?? "Choose a language"}
                rightIcon={playGonImg}
                maxW={'400px'}
                minW={'280px'}
                w={{sm: '100%', md: '100%', lg: '10%'}}
                textWidth={{sm: '100%', md: '', lg: '17ch'}}
                options={[...new Set(sortStrArray(totalData.map(s => s.lang)))]}
                onSelect={(val) => onSelectLang(val)}
              />              
              <Select
                title={book ?? "Book"}
                rightIcon={playGonImg}
                options={[...new Set(sortBookArray(selectedData))]}
                onSelect={(val) => setBook(val)}
                w={{sm: '100%', md: '100%', lg: '165px'}}
                textWidth={{sm: '100%', md: '', lg: '7ch'}}
                maxW={'400px'}                
              />              
              <Select
                title={chapter ?? "Chapter"}
                rightIcon={playGonImg}
                w={{sm: '100%', md: '100%', lg: '165px'}}
                textWidth={{sm: '100%', md: '', lg: '7ch'}}
                maxW={'400px'}                
                options={[
                  ...new Set(
                    selectedData
                      .filter(s => s.book === book)
                      .map(item => item.chapter)
                  )
                ]}
                onSelect={(val) => onSelectChapter(val)}
              />  
              <AudioPlayer
                w={{sm: '100%', md: '100%', lg: 'unset'}}
                maxW={'400px'}
                minW={'280px'}
                url={audio} 
                autoPlay={autoPlay}
                onEnded={() => gotoNextChapter()}
                metaData={`${lang}/${book}/${chapter}`}
              />
          </Flex>
        </Box>

        <Box>
          <Flex justifyContent={"end"} alignItems={"end"} marginTop={10}>
            <Text
              color={"white"}
              textAlign={"right"}
              fontSize={'20px'}
              lineHeight={'1.5'}
              style={{
                fontFamily: "Roboto",
                fontWeight: "500",
                textAlign: isMobile ? "center" : "",
              }}
              dangerouslySetInnerHTML={{ __html: formatRichTextStyle(documentToHtmlString(elements?.text)) }}
            >
            </Text>
          </Flex>
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default ListBible;
