import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Image,
  Input,
  Menu,
  MenuButton,
  MenuList,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

const Select = ({ w, minW, maxW, title, rightIcon, options, textWidth, onSelect }) => {
  const [searchedList, setSearchedList] = useState([]);
  const [keyword, setKeyWord] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onSelectItem = (s) => {
    onClose();
    onSelect(s)
  }

  useEffect(() => {
    setSearchedList(options);
  }, [options])
  
  useEffect(() => {
    if (keyword !== "")
      setSearchedList(
        options.filter((item) =>
          item.toLowerCase().startsWith(keyword.toLowerCase()),
        ),
      );
    else setSearchedList(options);
  }, [options, keyword])

  useEffect(() => {
    setKeyWord('');
  }, [title])
  
  return (
    <Box id={`Select-${title}`} w={w} minW={minW} maxW={maxW}>
      <Menu width={w} onClose={onClose} isOpen={isOpen} onOpen={onOpen}>
        <MenuButton
          color={"white"}
          rightIcon={<Image w={[4, 4, "100%"]} ml={10} src={rightIcon} alt="Select Audio"/>}
          opacity={0.8}
          bg={"rgb(45,59,65)"}
          borderRadius="6px"
          py={[5, 5, 7]}
          fontWeight={"bold"}
          fontSize={"22px"}
          w={'100%'}
          as={Button}
          pl={'23px'}
          fontFamily={"Roboto"}
        >
          <Text w={textWidth} className="text-string-capitalize" overflow={'hidden'} textAlign={'left'} fontFamily={"Roboto"} >
            {title}
          </Text>
        </MenuButton>
        <MenuList width={w} minW={minW} maxW={maxW} overflowY={"scroll"} height={"270px"} padding={5}>
          <Input
            onChange={(e) => setKeyWord(e.target.value)}
            placeholder="Enter search query"
            borderWidth={1}
            height={"50px"}
            fontFamily={'Roboto'}
            value={keyword}
          />
          <Box mt="10px" borderWidth={1} borderRadius="8px" fontFamily={"Roboto"}>
            {searchedList?.length > 0 ? (
              searchedList.map((item, index) => (
                <Box 
                  key={`listen-${index}`} 
                  fontFamily={"Roboto"} 
                  onClick={() => onSelectItem(item)} 
                  p={'5px 9px'}
                  cursor={'pointer'}
                  borderRadius={'5px'}
                  backgroundColor={ item === title ? '#00000036' : 'unset'}
                  className="text-string-capitalize"
                >
                  {item}
                </Box>
              ))
            ) : (
              <Box p={'5px 9px'} textAlign={'center'}>No results found!</Box>
            )}
          </Box>
        </MenuList>
      </Menu>
    </Box>
  );
};

export default Select;
