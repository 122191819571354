import React, { useEffect, useState } from 'react'
import { Box, Grid, GridItem, Image, ListItem, OrderedList, Text } from "@chakra-ui/react";
import { scroller } from 'react-scroll';
import { formatAudios, formatInternalDetail, formatOctagons, formatRichTextStyle } from "../utils/formatElements";
import { contentful } from "../Config/contentful";
import Header from '../Components/Header/index';
import Footer from '../Components/Footer/index';
import Hexagone from '../Components/Common/Hexagone';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import AudioPlayer from '../Components/Bible/Listen/AudioPlayer';

const KnottedStrings = () => {
  const [internalDetailData, setInternalDetailData] = useState(null);
  const [hexagonData, setHexagonData] = useState(null);
  const [audios , setAudios] = useState(null);

  const getContentfulData = async () => {
    const response = await contentful.getEntries("");
    if(!response.items) return;

    const octagonCollection = [];
    const audioCollection = [];
    await response.items.forEach((item) => {
      const {sys: {contentType: {sys: {id}}}} = item;

      // Get Collection of elements
      switch (id.toLowerCase()) {
        case "octagondetail":
          octagonCollection.push(item);
          break;
        case "knottedstringsaudios":
          audioCollection.push(item);
          break;
        case "internaldetail":
          setInternalDetailData(formatInternalDetail(item));
          break;
        default:
          break;
      }
    });
    
    setHexagonData(formatOctagons(octagonCollection));
    setAudios(formatAudios(audioCollection));
  }

  useEffect( () => {
    scroller.scrollTo('content');
    getContentfulData();
  }, []);

  return (
    <>
      <Header />
      <Box id={'content'} pb={'300px'} pt={'200px'} className='container' fontFamily={"Roboto"}>
        <Grid
          gridTemplateColumns={{lg: "repeat(1, 1fr)", xl: "repeat(2, 1fr)"}}
          columnGap='20px'
          rowGap='20px'
        >
          <GridItem>
            <Image src={internalDetailData?.image1}/>
          </GridItem>
          <GridItem>
            <Text
              fontSize={{ base: "15px", md: "20px", lg: "30px" }}
              lineHeight={{ base: "18px", md: "23px", lg: "33px" }}
              color={"black"}
              fontWeight={"medium"}
              fontFamily={'"Roboto"'}
              mb={'25px'}
              maxW={'1130px'}
              px={{xl: '30px'}}
              dangerouslySetInnerHTML={{ __html: formatRichTextStyle(documentToHtmlString(internalDetailData?.text)) }}
            ></Text>
          </GridItem>
        </Grid>
        <Grid
          gridTemplateColumns={{lg: "repeat(1, 1fr)", xl: "repeat(2, 1fr)"}}
          columnGap='20px'
        >
          <GridItem></GridItem>
          <GridItem px={{sm: '10px', xl: '30px'}}>
            <Text 
              lineHeight='50px'
              fontSize='22px'
              fontWeight={'bold'}
              textTransform='uppercase'
              color='#53a3ca'
            >Chapters</Text>
            <OrderedList 
              lineHeight='33px'
              fontSize={{sm: '18px',xl: '20px'}}
              textDecor='underline'
              color='#53a3ca'
            >
              {
                audios?.map(item => <>
                  <ListItem
                    key={item.url}
                    listStyleType='unset'
                    cursor='pointer'
                  >
                    <AudioPlayer url={item.url} title={item.title} simplePlayer />
                  </ListItem>
                </>)
              }
            </OrderedList>
          </GridItem>
        </Grid>
      </Box>
      <Hexagone elements={hexagonData} pb={"200px"} dropShadow={'drop-shadow(rgba(0, 0, 0, 0.9) 0px 0px 15px)'} />
      <Footer />
    </>
  )
}

export default KnottedStrings